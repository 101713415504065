import { useEffect, useState } from "react";
import NPCard from "../../../comp/card/NPCard";
import NPList from "../../../comp/list/NPList";
import TranAPI from "../../../api/TranAPI";
import { NPLoadingBar } from "../../../comp/NPLoadingBar";
import './AgencyDashboard.css'
import NPSimpleButton from "../../../comp/NPSimpleButton";

import { ReactComponent as CarSVG } from '../../../icons/car.svg';
import { ReactComponent as PlusSVG } from '../../../images/add.svg';
import { ReactComponent as TickSVG } from '../../../icons/tick.svg';
import { ReactComponent as HandsSVG } from '../../../icons/handshake.svg';
import { ReactComponent as CarKeySVG } from '../../../icons/car-key.svg';

import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { NPNotificationCard } from "../../../comp/card/NPNotificationCard";
import { CustomerTransactionLegend } from "./CustomerTransactions";

export function AgencyDashboard() {

    const [loadingPaidTransactions, setLoadingPaidTransactions] = useState(true)
    const [loadingTransactions, setLoadingTransactions] = useState(true)
    const [paidTransactions, setPaidTransactions] = useState()
    const [transactions, setTransactions] = useState()

    const history = useHistory()
    const location = useLocation()

    const init = () => {
        loadPaidTransactions()
        loadTransactions()
    }

    /**
     * Load transactions for which the buyer's money has been received
     */
    const loadPaidTransactions = async () => {

        const timer = setTimeout(() => { setLoadingPaidTransactions(true) }, 500)

        const response = await new TranAPI().getTransactions("paid")

        clearTimeout(timer)
        setLoadingPaidTransactions(false)

        if (response && response.transactions) setPaidTransactions(response.transactions)

    }

    /**
     * Load all transactions
     */
    const loadTransactions = async () => {

        const timer = setTimeout(() => { setLoadingTransactions(true) }, 500)

        const response = await new TranAPI().getTransactions()

        clearTimeout(timer)
        setLoadingTransactions(false)

        if (response && response.transactions) setTransactions(response.transactions)

    }

    /**
     * Converts the data for the NPList
     */
    const paidTxConverter = (item) => {

        return {
            values: [
                { value: item.licensePlates },
                { value: item.price },
                { value: item.createdOn },
            ]
        }

    }

    /**
     * Converts the data for the NPList
     */
    const txConverter = (item) => {

        let statusIcon;
        if (item.buyerPaid && !item.propertyTransferred) statusIcon = <CarSVG />
        else if (item.propertyTransferred == true && !item.sellerWireDone) statusIcon = <CarKeySVG />
        else if (item.sellerWireDone == true) statusIcon = <HandsSVG />

        return {
            values: [
                { value: item.licensePlates },
                { value: item.price },
                { value: item.createdOn },
                { value: statusIcon },
            ]
        }

    }

    useEffect(init, [])

    return (
        <div className="screen agency-dashboard">

            <div className="new-tx-container">
                <NPSimpleButton title="Crea una Nuova Transazione" img={<PlusSVG />} onClick={() => { history.push('/newtx') }} />
            </div>

            <NPNotificationCard icon={<TickSVG />} message="La transazione è stata salvata correttamente." trigger={location.state && location.state.txInserted} />

            <NPCard>
                <div className="card-title">Transazioni pronte per il Passaggio di Proprietà</div>
                {!loadingPaidTransactions &&
                    <>
                        <div className="card-desc">Lista delle transazioni per cui Pleggit ha ricevuto il bonifico (incluse commissioni di Agenzia) dal Compratore e per cui è possibile proseguire con il passaggio di proprietà.</div>
                        <div className="list-container">
                            <NPList
                                data={paidTransactions}
                                dataConverter={paidTxConverter}
                                types={["string", "price", "short-date"]}
                                mobileVisible={[true, false, true]}
                                elementsSizing={[null, "20%", "15%"]}
                                headers={["Targa", "Prezzo", "Creata il"]}
                                onItemClick={(item) => { history.push(`/transactions/${item.id}`) }}
                            />
                        </div>
                    </>
                }
                {loadingPaidTransactions && <NPLoadingBar />}
            </NPCard>

            <NPCard>
                <div className="card-header">
                    <div className="card-title">Transazioni registrate in Pleggit</div>
                    <div className="button-container">
                        <CustomerTransactionLegend />
                    </div>
                </div>
                {!loadingTransactions &&
                    <>
                        <div className="card-desc">Lista di tutte le transazioni registrate in Pleggit.</div>
                        <div className="list-container">
                            <NPList
                                data={transactions}
                                dataConverter={txConverter}
                                types={["string", "price", "short-date", "icon"]}
                                mobileVisible={[true, false, true, true]}
                                elementsSizing={[null, "20%", "15%", "5%"]}
                                headers={["Targa", "Prezzo", "Creata il", "Stato"]}
                                onItemClick={(item) => { history.push(`/transactions/${item.id}`) }}
                            />
                        </div>
                    </>
                }
                {loadingTransactions && <NPLoadingBar />}
            </NPCard>

        </div>
    )
}