import { LabeledValue, Tag } from "./DashboardElements";
import './CustomerTransactions.css'
import NPIconButton from "../../../comp/NPIconButton";
import Popup from 'reactjs-popup';
import { ReactComponent as CarSVG } from '../../../icons/car.svg'
import { ReactComponent as KeySVG } from '../../../icons/car-key.svg'
import { ReactComponent as HandsSVG } from '../../../icons/handshake.svg'
import { ReactComponent as CloseSVG } from '../../../icons/close.svg'
import { ReactComponent as WarnSVG } from '../../../icons/warning.svg'

import Cookies from "universal-cookie";
import { TransactionStateIcon } from "../../../comp/TransactionStateIcon";
import { useState } from "react";
import NPCard from "../../../comp/card/NPCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const cookies = new Cookies()

export function CustomerTransactions({ transactions, loading }) {

    return (
        <div className="">
            {transactions && transactions.map(
                (tx) => <CustomerTransaction transaction={tx} key={Math.random()} />
            )}
        </div>
    )
}


export function CustomerTransaction({ transaction }) {

    const user = cookies.get('user')

    const history = useHistory()

    /**
     * When the user clicks on the transaction, 
     * navigate to the transaction detail
     */
    const onTxClick = () => {

        history.push(`/transactions/${transaction.id}`)
    }

    return (
        <div className="customer-transaction" onClick={onTxClick}>
            <div className="cell" style={{ minWidth: "15%" }}>
                <LabeledValue label="Veicolo" value={transaction.licensePlates} />
            </div>
            <div className="cell" style={{ flex: 1, minWidth: "15%" }}>
                <LabeledValue label="Controparte" value={user.email == transaction.buyerEmail ? transaction.sellerEmail : transaction.buyerEmail} />
            </div>
            <div className="cell" style={{ minWidth: "15%" }}>
                <Tag content={user.email == transaction.buyerEmail ? "acquisto" : "vendita"} />
            </div>
            <div className="cell" style={{ minWidth: "5%" }}>
                <TransactionStateIcon transaction={transaction} />
            </div>
        </div>
    )
}


export function CustomerTransactionLegend() {

    const [showLegend, setShowLegend] = useState(false)

    return (

        <div className="customer-tx-legend">
            <LegendButton label={showLegend ? "Nascondi Leggenda" : "Leggenda icone"} onLegendClick={() => { setShowLegend(!showLegend) }} />

            <Popup open={showLegend} onClose={() => { setShowLegend(false) }}>
                <NPCard>
                    <div className="customer-tx-legend-popup">
                        <div className="card-header">
                            <div className="card-title">Leggenda Stati Transazione</div>
                            <div>
                                <NPIconButton image={<CloseSVG />} onClick={() => { setShowLegend(false) }} />
                            </div>
                        </div>
                        <div className="content">
                            <div className="icon"><CarSVG /></div>
                            <div className="text">
                                <div className="title">E' possibile effettuare il passaggio di proprietà.</div>
                                <div className="desc">I fondi per la transazione sono stati ricevuti da Pleggit e sono al sicuro. Le due parti possono procedere con il passaggio di proprietà. </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="icon"><KeySVG /></div>
                            <div className="text">
                                <div className="title">Il passaggio di proprietà è stato effettuato.</div>
                                <div className="desc">Il passaggio di proprietà è stato effettuato in agenzia. I fondi della transazione non sono ancora stati versati al Venditore.</div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="icon"><HandsSVG /></div>
                            <div className="text">
                                <div className="title">La compravendita è conclusa.</div>
                                <div className="desc">Il passaggio di proprietà è stato effettuato e i fondi della transazione sono stati versati al Venditore. La transazione di compravendita è conclusa.</div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="icon warn"><WarnSVG /></div>
                            <div className="text">
                                <div className="title">E' necessario il tuo intervento.</div>
                                <div className="desc">Per procedere con la tua transazione, è necessario del tuo intervento. Apri la transazione e segui eventuali istruzioni presenti.</div>
                            </div>
                        </div>
                    </div>
                </NPCard>
            </Popup>

        </div>

    )
}

function LegendButton({ label, onLegendClick }) {

    return (
        <div className="legend-button" onClick={onLegendClick}>
            {label}
        </div>
    )

}