import './NPExpander.css';
import {ReactComponent as DotsSVG} from '../icons/dots.svg';

export function NPExpander({onClick}) {

    return (
        <div className='np-expander' onClick={onClick}>
            <DotsSVG />           
        </div>
    )
}