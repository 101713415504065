import AccessAPI from '../api/AccessAPI';

/**
 * Verifies if the user is signed in
 * 
 * @returns Promise with the following object:
 * - {signedIn: true | false, authResponse} in case of success
 * - {error: "notloaded", message: ...} in case of failure
 * 
 */
export function checkSigninStatus() {

    return new Promise((success, failure) => {

        if (!window.FB) { failure({ error: "notloaded", message: "Facebook API not loaded" }); return; }

        window.FB.getLoginStatus((response) => {

            if (response && response.status === 'connected') success({ signedIn: true, authResponse: response.authResponse });
            else success({ signedIn: false });

        });

    })
}

/**
 * Checks if the user is authorized to access the app
 * If the user is authorized, returns the user profile
 * @returns Promise with the user profile, if successfull: 
 * - {user: {userId, email, name, givenName, familyName, token}}
 */
export function checkUserAuthorization(authResponse) {

    return new Promise((success, failure) => {

        if (!window.FB) { failure({ error: "notloaded", message: "Facebook API not loaded" }); return; }

        new AccessAPI().checkUserAuth(authResponse.accessToken, 'fb').then((checkRes) => {

            if (checkRes.code == 200) {

                // Retrieve user data
                window.FB.api('/me?fields=email,first_name,last_name,name', function (response) {
                    success({
                        user: {
                            email: response.email,
                            userId: response.id,
                            name: response.name,
                            givenName: response.first_name,
                            familyName: response.last_name,
                            role: checkRes.role
                        }
                    });
                });
            }
            else failure({ error: "notauthorized" });

        })
    })
}

/**
 * Signs in the user into Google. 
 * Also checks if the user is authorized to access the app and in case he or she is, returns the user profile.
 * 
 * @returns Promise
 */
export function signIn() {

    return new Promise((success, failure) => {

        if (!window.FB) { failure({ error: "notloaded", message: "Facebook API not loaded" }); return; }

        window.FB.login((response) => {

            if (response.authResponse) {

                checkUserAuthorization(response.authResponse).then(success, failure);

            }
            else {

                console.log('User cancelled the Facebook login or did not fully authorize.');

                failure({ error: "cantsignin" });

            }
        }, { scope: 'email' });

    })

}

/**
 * Returns the user profile from the Facebook API
 * @returns Promise
 */
export function getUserProfile() {

    return new Promise((success, failure) => {

        if (window.FB) {

            window.FB.api('/me?fields=email,first_name,last_name,name', function (response) {

                success({
                    result: true,
                    user: { email: response.email, userId: response.id, name: response.name, givenName: response.first_name, familyName: response.last_name }
                })

            })
        }
        else {

            let error = "FB API seems not to be loaded.";

            console.error(error);

            failure(error);

        }

    })
}

/**
 * Returns the token associated with the user
 */
export function getUserToken() {

    return new Promise((success, failure) => {

        if (!window.FB) { failure({ error: "notloaded", message: "Facebook API not loaded" }); return; }

        window.FB.getLoginStatus((response) => {

            if (response && response.status === 'connected') success(response.authResponse.accessToken);
            else failure({ error: "notsignedin", nessage: "The user is not signed in with Facebook!" });

        });
    })
}