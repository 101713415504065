import NPIconButton from '../NPIconButton'
import './NPCardMessage.css'
import { ReactComponent as TickSVG } from '../../icons/tick.svg';
import moment from 'moment';
/**
 * 
 * @param {text} Mandatory. The text to provide.
 * @param {color} Optional. One of "primary", "complementary", "accent", "accent-mkt". Default is "primary"
 * @param {icon} Optional. One of "ok". Default is null.
 * @param {onOk} Optional. If provided, the widget will show an ok button. This callback will be called when pressesd. Default null.
 * @param {date} Optional. If provided, a date formatted YYYYMMDD to display
 * 
 * @returns 
 */
export function NPCardMessage({ text, color, icon, onOk, date }) {

    const messageColor = color ? color : "primary"
    const noBorder = icon != null

    let loadedIcon;
    if (icon == 'ok') loadedIcon = <TickSVG />

    return (
        <div className={`np-card-message ${messageColor} ${noBorder ? 'no-border' : ''}`}>
            {icon && <div className='icon'>{loadedIcon}</div>}
            <div>
                {date && <div className="date">{moment(date, "YYYYMMDD").format("DD.MM.YYYY")}</div>}
                <div className='text'>{text}</div>
            </div>
            {onOk && <div className='ok-button'><NPIconButton image={<TickSVG />} onClick={onOk} /></div>}
        </div>
    )
}