import AccessAPI from '../api/AccessAPI';

/**
 * Verifies if the user is signed in
 * 
 * @returns Promise with the following object:
 * - {signedIn: true | false} in case of success
 * - {error: "notloaded", message: ...} in case of failure
 * 
 */
export function checkSigninStatus() {

    return new Promise((success, failure) => {

        if (!window.gapi) { failure({ error: "notloaded", message: "Google API not loaded" }); return; }

        let auth = window.gapi.auth2.getAuthInstance();

        if (auth.isSignedIn.get()) success({ signedIn: true });
        else success({ signedIn: false });

    })
}

/**
 * Checks if the user is authorized to access the app
 * If the user is authorized, returns the user profile
 * @returns Promise with the user profile, if successfull: 
 * - {user: {userId, email, name, givenName, familyName, token}}
 */
export function checkUserAuthorization() {

    return new Promise((success, failure) => {

        if (!window.gapi) { failure({ error: "notloaded", message: "Google API not loaded" }); return; }

        let googleUser = window.gapi.auth2.getAuthInstance().currentUser.get();

        new AccessAPI().checkUserAuth(googleUser.getAuthResponse().id_token, 'google').then((checkRes) => {

            if (checkRes.code == 200) {

                let profile = googleUser.getBasicProfile();

                success({
                    user: {
                        userId: googleUser.getId(),
                        email: profile.getEmail(),
                        name: profile.getName(),
                        givenName: profile.getGivenName(),
                        familyName: profile.getFamilyName(),
                        token: googleUser.getAuthResponse().id_token, 
                        role: checkRes.role
                    }
                })

            }
            else failure({ error: "notauthorized" });

        })
    })
}

/**
 * Signs in the user into Google. 
 * Also checks if the user is authorized to access the app and in case he or she is, returns the user profile.
 * 
 * @returns Promise
 */
export function signIn() {

    return new Promise((success, failure) => {

        if (!window.gapi) { failure({ error: "notloaded", message: "Google API not loaded" }); return; }

        window.gapi.auth2.getAuthInstance().signIn().then((googleUser) => {

            checkUserAuthorization().then(success, failure);

        }, (err) => {

            console.log(err);

            failure({ error: "cantsignin" });
        });

    })

}

/**
 * Returns the token associated with the user
 */
export function getUserToken() {

    return new Promise((success, failure) => {

        if (!window.gapi) { failure({ error: "notloaded", message: "Google API not loaded" }); return; }

        let token = window.gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token;

        success(token);
    })
}