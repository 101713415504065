import './BlockDivider.css'

export function BlockDivider({ title }) {

    return (
        <div className="block-divider">
            {title}
        </div>
    )

}