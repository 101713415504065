import Cookies from 'universal-cookie';

const cookies = new Cookies();

/**
 * Stores the google user in cookies
 * @param {object} authenticatedUser the user to store. Contains the following
 * - userId - the user ID
 * - email  - the user email
 * - name - the user complete name
 * - token - the received token
 * 
 * Optionally, these fields can be passed, if available (based on the auth provider): 
 * - givenName - user first name
 * - familyName - ser last name
 */
export function storeUser(authenticatedUser, authProvider) {

    // Define the user
    let user = { 
        userId: authenticatedUser.userId,
        authProvider: authProvider, 
        name: authenticatedUser.name, 
        givenName: authenticatedUser.givenName, 
        familyName : authenticatedUser.familyName, 
        email: authenticatedUser.email, 
        idToken: authProvider == 'pleggit' ? authenticatedUser.accessToken : authenticatedUser.token, 
        role: authenticatedUser.role, 
        agencyId: authenticatedUser.agencyId,
     };

    // Set the cookies
    cookies.set('user', user, { path: '/' });

}

/**
 * Indicates whether this profile is considered "complete" or not. 
 * 
 * @param {object} profile the User Profile, as retrieved from the Profile API
 */
export function isProfileCompleted(profile) {

    if (!profile) return false;

    if (!profile.cf && !profile.piva) return false;

    if (!profile.residenceAddress && !profile.regOfficeAddress) return false;

    return true;

}