import NPAPI from './NPAPI';

export default class CompanyAPI {

  getCompanyInfo() {

    return new NPAPI({ skipAuth: true }).fetch('company', '/info').then((response) => response.json());

  }

}
