import NPAPI from './NPAPI';

export default class TranAPI {

  /**
   * Creates the transaction
   */
  async postTransaction(data) {

    let tran = {
      licensePlates: data.licensePlates,
      createdBy: "agency",
      price: parseFloat(data.price),
      fees: data.fees,
      agencyFees: data.agencyFees,
      buyerEmail: data.buyerEmail,
      sellerEmail: data.sellerEmail,
      agencyId: data.agencyId,
      agencyEmail: data.agencyEmail
    };

    return new NPAPI().fetch('tran', '/transactions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(tran)
    }).then((response) => response.json());

  }

  /**
   * Posts the IBAN to the transaction
   */
  async postIBAN(tid, iban) {

    return new NPAPI().fetch('tran', `/transactions/${tid}/iban`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        iban: iban
      })
    }).then((response) => response.json());

  }

  /**
   * Mark the property transfer as done on the transaction
   */
  async postPropertyTransferred(tid) {

    return new NPAPI().fetch('tran', `/transactions/${tid}/transferred`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((response) => response.json());

  }

  async getTransactions(filter) {

    return new NPAPI().fetch('tran', `/transactions?filter=${filter}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      },
    }).then((response) => response.json());
  }

  /**
   * Retrieves your own transactions
   */
  async getMyTransactions() {

    return new NPAPI().fetch('tran', `/me/transactions`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      },
    }).then((response) => response.json());
  }

  async getPaymentLink(tid) {

    return new NPAPI().fetch('tran', `/transactions/${tid}/plink`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      },
    }).then((response) => response.json());
  }

  /**
   * Get the detail of a transaction
   */
  async getTransactionDetail(tid) {

    return new NPAPI().fetch('tran', `/transactions/${tid}`).then((response) => response.json());

  }


}
