import NPLabeledValue from "../../NPLabeledValue";
import NPCard from "../NPCard";
import { NPCardMessage } from "../NPCardMessage";
import './AgencyTransactionPaymentCard.css';

export function AgencySellerPaymentCard({ transaction }) {

    const amountWiredToSeller = parseFloat(transaction.price) - parseFloat(transaction.fees) - (transaction.agencyFees ? parseFloat(transaction.agencyFees) : 0)

    return (
        <NPCard>
            <div className="agency-tx-payment-card row">
                <div className="status">
                    {!transaction.sellerWireDone &&
                        <>
                            <div className="title">Pleggit è in procinto di versare i fondi al Venditore.</div>
                            <div className="info-text">Importo che sarà bonificato al Venditore:</div>
                            <div className="row">
                                <NPLabeledValue label="Importo" value={amountWiredToSeller} type="currency" />
                            </div>
                        </>
                    }
                    {transaction.sellerWireDone === true && <NPCardMessage icon="ok" date={transaction.sellerWireDoneOn} text={`Il bonifico di ${amountWiredToSeller.toLocaleString('it-IT', { style: 'currency', currency: "EUR", minimumFractionDigits: 2 })} è stato effettuato a favore del Venditore.`} />}
                </div>
            </div>
        </NPCard>
    )
}