export const APIS = {
  access: process.env.REACT_APP_ACCESS_API_ENDPOINT,
  profile: process.env.REACT_APP_PROFILE_API_ENDPOINT,
  fs: process.env.REACT_APP_FS_API_ENDPOINT,
  tran: process.env.REACT_APP_TX_API_ENDPOINT,
  adminPup: process.env.REACT_APP_ADMIN_PUP_API_ENDPOINT,
  payments: process.env.REACT_APP_PAY_API_ENDPOINT,
  company: process.env.REACT_APP_COMPANY_API_ENDPOINT,
  fees: process.env.REACT_APP_FEES_API_ENDPOINT,
  tranlog: process.env.REACT_APP_TRANLOG_API_ENDPOINT,
  notif: process.env.REACT_APP_NOTIF_API_ENDPOINT, 
  auth: process.env.REACT_APP_AUTH_API_ENDPOINT,
  dispute: process.env.REACT_APP_DISPUTE_API_ENDPOINT,
  info: process.env.REACT_APP_INFO_API_ENDPOINT, 
  invoice: process.env.REACT_APP_INVOICE_API_ENDPOINT, 
  monkey: process.env.REACT_APP_MONKEY_API_ENDPOINT, 
}

export const EVENTS = {

  // Profile EVENTS ----------------------------
  // Profile verified
  profileVerified: "profile-verified",

  // Profile OCR Failed
  profileOCRFailed: "profile-ocrFailed",

  // Transaction EVENTS ------------------------
  // Transaction has been created
  txCreated: "transaction-created",

  // Transaction has been modified
  txModified: "transaction-modified",

  // Transaction has been matched
  txMatched: "transaction-matched",

  // The property proof document has been posted
  txPropertyProofUploaded: "transaction-propertyProofUploaded",

  // The property transfer document has been posted
  txPropertyTransferUploaded: "transaction-propertyTransferUploaded",

  // Payment received
  txBuyerPaymentReceived: "transaction-buyerPaymentReceived",

  // The provided counterpart ecode does not belong to any transaction
  txInexistentECodeProvided: "transaction-inexistentECodeProvided",

  // The provided counterpart ecode belongs to a transaction that does not match this transaction (refers to a different item, for example)
  txInconsistentECodeProvided: "transaction-inconsistentECodeProvided",

  // The provided counterpart ecode was matched and is valid
  txValidECodeProvided: "transaction-validECodeProvided",

  // The counterpart has correctly inserted the provided ecode
  txCounterpartHasInsertedECode: "transaction-counterpartHasInsertedECode",

  // The iconfirmtx has been received, but the data of the transaction doesn't actually seem complete, so that shouldn't have been possible
  txTransactionUserConfirmationFailed: "transaction-transactionUserConfirmationFailed",

  // The iconfirmtx has been received, validated and recorded
  txTransactionUserConfirmationSucceded: "transaction-transactionUserConfirmationSucceded",

  // The OCR of one of the transaction document has been forced (to pass)
  txDocOCRForced: "transaction-docOCRForced",

  // Transaction has been marked as "completed"
  txCompleted: "transaction-completed",

  // Transaction has been deleted
  txDeleted: "transaction-deleted"

}