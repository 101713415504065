import React, { Component } from 'react';
import './PleggitAnimatedLoader.css'
import { ReactComponent as LogoSVG } from '../images/logo/logo.svg';

export default class PleggitAnimatedLoader extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="pleggit-anim-loader">
                <div className="logo-container">
                    <LogoSVG width="100%" height="100%" />
                </div>
            </div>
        )
    }
}