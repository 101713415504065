import NPButton from '../../../../comp/NPButton'
import { ReactComponent as TickSVG } from '../../../../icons/tick.svg'
import '../CustomerProfile.css'

/**
 * Shows the "Profile Completed" message. 
 * 
 * This component ASSUMES THAT THERE IS ONLY ONE TRANSACTION when the user first creates its profile. 
 * By assuming this, we're able to provide instructions to insert the IBAN or instructions for the transfer of funds. 
 */
export function CustomerFormFinished({ partyType, onClose }) {

    return (
        <div className="form finished">
            <div className="success">
                <div className="icon"><TickSVG /></div>
                <div className="title"><b>Profilo Completato!</b></div>
            </div>

            {partyType == 'seller' &&
                <div className='text'>
                    <p>Per mettere in sicurezza i fondi della tua compravendita in corso, verifica i dati della transazione nella sezione "Le tue transazioni" qui sotto e <b>inserisci il tuo IBAN</b>.</p>
                    <p>L'IBAN inserito verrà utilizzato per <b>bonificarti l'importo della transazione</b>, decurtato dalle commissioni, una volta completato il Passaggio di Proprietà.</p>
                </div>
            }
            
            {partyType == 'buyer' &&
                <div className='text'>
                    <p>Per mettere in sicurezza i fondi della tua compravendita in corso, verifica i dati della transazione nella sezione "Le tue transazioni" qui sotto e <b>segui le istruzioni per effettuare il bonifico</b>.</p>
                </div>
            }

            <div style={{ marginTop: 24 }}></div>

            <div className="buttons-container">
                <div style={{ flex: 1 }}></div>
                <NPButton label="Chiudi" onClick={onClose} />
            </div>
        </div>
    )
}