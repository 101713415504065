import NPAPI from './NPAPI';

export default class FeesAPI {

  getBuyerSCTAmount(transactionPrice, transactionDate) {

    return new NPAPI().fetch('fees', `/buyer/sctAmount?price=${parseFloat(transactionPrice)}&date=${transactionDate}`).then((response) => response.json());

  }

  getRebateClasses() {

    return new NPAPI().fetch('fees', '/rebate/classes').then((response) => response.json());

  }

  getRebateCodes() {

    return new NPAPI().fetch('fees', '/rebate/codes').then((response) => response.json());

  }

  newRebateCode(rebateClass, notes) {

    return new NPAPI().fetch('fees', '/rebate/codes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        rebateClass: rebateClass, 
        notes: notes
      })
    }).then((response) => response.json());

  }

}
