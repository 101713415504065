import './NPValidationError.css'
import { ReactComponent as WarningSVG } from '../icons/warning.svg'

export function NPValidationError({ errorMessage }) {

    return (
        <div className="validation-error-container">
            <div className="icon"><WarningSVG /></div>
            <div className="text">{errorMessage}</div>
        </div>
    )

}