import * as config from '../Config';
import moment from 'moment';
import Cookies from 'universal-cookie';
import * as GoogleAuth from '../util/AuthProviderGoogle';
import * as FacebookAuth from '../util/AuthProviderFB';
import * as PleggitAuth from '../util/AuthProviderPleggit'

const cookies = new Cookies();

export function newCid() {

  let ts = moment().format('YYYYMMDDHHmmssSSS');

  let random = (Math.random() * 100000).toFixed(0).padStart(5, '0');

  return ts + '-' + random;

}

/**
 * Wrapper for the fetch() React method that adds the required fields for Toto authentication
 */
export default class NPAPI {

  constructor(options) {
    this.options = options;
  }

  skipAuth() {

    if (this.options && this.options.skipAuth) return true;

    return false;
  }

  fetch(api, url, options) {

    if (options == null) options = { method: 'GET', headers: {} };
    if (options.headers == null) options.headers = {};

    // Get the auth provider
    let authProvider = cookies.get('user') ? cookies.get('user').authProvider : null;

    // Get the token, based on the auth provider
    let tokenPromise;
    if (!options || !options.noAuth) {
      if (authProvider == 'google') tokenPromise = GoogleAuth.getUserToken();
      else if (authProvider == 'fb') tokenPromise = FacebookAuth.getUserToken();
      else if (authProvider == 'pleggit') tokenPromise = PleggitAuth.getUserToken();
    }

    if (tokenPromise) {

      return tokenPromise.then((token) => {

        // Adding standard headers
        options.headers['Accept'] = 'application/json';
        options.headers['x-correlation-id'] = newCid();

        if (!this.skipAuth()) {
          options.headers['Authorization'] = 'Bearer ' + token;
          options.headers['auth-provider'] = authProvider;
        }

        return fetch(config.APIS[api] + url, options);


      }, (err) => {
        console.error("Could not retrieve the token from auth provider " + authProvider);
        console.log(err);
        return;
      })
    }
    else {
      // Unauthenticated call
      // Adding standard headers
      options.headers['Accept'] = 'application/json';
      options.headers['x-correlation-id'] = newCid();

      return fetch(config.APIS[api] + url, options);
    }
  }
}
