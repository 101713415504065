import React, { Component } from 'react';
import './NPPopup.css';

/**
 * Popup 
 * 
 * Parameters:
 * 
 *  - position              :   (MAND) the position of the popup. The position has to be absolute to the screen. 
 *                              An object {top: .., left: ..} is expected
 *  - style                 :   (OPT, default 'standard') the style of the popup
 *                              It can be: 'standard', 'validation'
 *  - verticalPlacement     :   (OPT, default 'top') Sets the vertical placement of the popup compared to the passed position
 *                              If 'centered' is passed, the popup will be vertically centered around the passed position.top
 *                              If 'bottom' is pased, the popup's bottom will be position at position.top
 *  - horizontalPlacement   :   (OPT, default 'left) Sets the horizontal alignment of the popup compared to the passed position
 *                              If 'centered' is passed, the popup will be horizontally centered around the passed position.left
 *                              If 'left' is passed, the popup will be put to the left of the position.left 
 *  - backgroundColor       :   (OPT, default white). Overrides the default white background color
 *  - paddingVertical       :   (OPT, default 24). Overrides the default padding top and bottom
 *  - popupOpacity          :   (OPT, default 0.9 or something like that). Overrides the default opacity, and THE OPACITY IS TRANSITIONED SO THAT IT LOOKS LIKE THE POPUP IS APPEARING SLOWLY
 *  - width                 :   (OPT, default auto). Overrides the default width
 *                          
 */
export default class NPPopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            top: 0,
            left: 0
        }
    }

    componentDidMount() {

        this.setState({
            height: this.el.clientHeight
        })

    }

    render() {

        let width = 'auto';
        if (this.props.width) width = this.props.width;


        let top = 0, left = 0;
        if (this.props.position && this.el) {
            top = this.props.position.top;
            left = this.props.position.left;

            // Vertical placement of the popup compared to the passed position
            if (this.props.verticalPlacement == 'centered' && this.state.height) top = this.props.position.top - this.state.height / 2;
            else if (this.props.verticalPlacement == 'bottom' && this.state.height) top = this.props.position.top - this.state.height;

            // Horizontal placement of the popup
            if (this.props.horizontalPlacement == 'centered' && this.el.clientWidth) left = parseFloat(this.props.position.left) - parseFloat(this.el.clientWidth / 2);
            else if (this.props.horizontalPlacement == 'left' && this.el.clientWidth) left = this.props.position.left - this.el.clientWidth;
        }

        // Classes
        let popupClass = "np-popup";
        if (this.props.style) popupClass += ' ' + this.props.style;
        if (this.props.style == 'validation') popupClass += ' font-small';
        if (this.props.wrapText === false) popupClass += ' nowrap';
        if (this.props.popupOpacity) popupClass += ' focused-easeInOut-motion';

        // Additional styles
        let styles = {};
        if (this.props.backgroundColor) styles.backgroundColor = this.props.backgroundColor;
        if (this.props.paddingVertical) { styles.paddingTop = this.props.paddingVertical; styles.paddingBottom = this.props.paddingVertical; }
        if (this.props.popupOpacity != null) styles.opacity = this.props.popupOpacity;

        // Popup 
        let popup = (
            <div ref={(el) => { this.el = el }} className={popupClass} style={{ top: top, left: left, width: width, ...styles }}>
                {this.props.children}
            </div>
        )

        return popup;
    }
}
