import React, { Component, useState } from 'react';
import { withTranslation } from 'react-i18next';
import './LoginScreen.css';
import './RegistrationScreen.css';
import NPButton from '../comp/NPButton';
import { withRouter } from 'react-router';
import NPTextInput from '../forms/NPTextInput'
import { ReactComponent as PleggitTitle } from '../images/logo/pleggit-title.svg';
import { ReactComponent as PleggitLogo } from '../images/logo/logo.svg';
import Footer from '../Footer';
import AuthAPI from '../api/AuthAPI';
import PleggitAnimatedLoader from '../comp/PleggitAnimatedLoader';

class RegistrationScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingFB: true,
            loadingGoogle: true
        }

    }

    componentDidMount() {
    }

    render() {

        const { t } = this.props;

        const queryParams = new URLSearchParams(window.location.search);
        const partyType = queryParams.get('pt') == 's' ? "seller" : "buyer";

        return (
            <div className="screen">

                <div className="registration-screen">

                    <WelcomeComponent partyType={partyType} />

                    <RegistrationComponent />

                </div>

                <div style={{ flex: 1 }}></div>

                <Footer />

            </div>
        )
    }
}

function WelcomeComponent({ partyType }) {

    return (
        <div className="welcome-component">
            <div className="title"><b>Benvenuto su Pleggit.</b></div>
            <div className="vspacing"></div>
            <div className="text sidebar">
                <i>
                    <p>Pleggit è l’unico servizio che <b>mette in tutta sicurezza</b> il pagamento in una compravendita di veicoli usati.</p>
                    <p>Niente più problemi o rischi di perdere il proprio denaro: Pleggit ti segue passo passo fino alla conclusione del tuo affare.</p>
                </i>
            </div>
            <div className="vspacing"></div>
            <div className="text">Crea il tuo Profilo Pleggit e metti in sicurezza il pagamento del veicolo che stai {partyType == 'seller' ? "vendendo" : "acquistando"}.</div>
            <div className="vspacing"></div>
            <div className="text">Pleggit è un servizio fornito in collaborazione con: </div>
            <div className="vspacing"></div>
            <div className="agency-image">
                <img src={require('../images/agencies/marinoni.png')} />
            </div>
        </div>
    )
}

function RegistrationComponent() {

    const [email, setEmail] = useState()
    const [pswd, setPswd] = useState()
    const [confirmPswd, setConfirmPswd] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const [loading, setLoading] = useState(false);
    const [registrationOver, setRegistrationOver] = useState(false)

    const queryParams = new URLSearchParams(window.location.search);
    const agencyId = queryParams.get('aid');

    console.log(`User Registration. User comes from Agency ${agencyId}`);

    const onRegister = async () => {

        if (!validate()) return;

        setLoading(true);

        const registrationOutcome = await new AuthAPI().registerUser({
            email: email,
            password: pswd,
            userType: "private",
            agencyId: agencyId
        })

        // Handle "User already exists" error
        if (registrationOutcome && registrationOutcome.userAlreadyExists === true) setErrorMessage("La mail inserita risulta essere già associata ad un utente registrato in Pleggit. Scegli un'altra email oppure effettua la login.")

        // If all went well, give a feedback to the user
        if (registrationOutcome && registrationOutcome.userId) setRegistrationOver(true)

        setLoading(false);
    }

    const validate = () => {

        if (!email) { setErrorMessage("È necessario inserire il tuo indirizzo email per proseguire."); return false; }
        if (!pswd) { setErrorMessage("E' necessario secgliere una password per proseguire."); return false; }
        if (!confirmPswd) { setErrorMessage("È necessario confermare la password per proseguire."); return false; }

        if (pswd.trim() != confirmPswd.trim()) { setErrorMessage("Le password non corrispondono."); return false; }

        setErrorMessage(null);

        return true;

    }

    return (
        <div className="login-container">

            <div className="logo-container">
                <div className="pleggit-logo" ><PleggitLogo /></div>
                <div className="pleggit-title" ><PleggitTitle /></div>
            </div>

            <div className="desc">Registrati e crea il tuo Profilo su Pleggit!</div>
            <div className="input-container">
                <NPTextInput label="Indirizzo email" mandatory={true} onChange={(v) => { setEmail(v) }} />
                <NPTextInput label="Crea una Password" type="password" mandatory={true} onChange={(v) => { setPswd(v) }} />
                <NPTextInput label="Conferma la Password" type="password" mandatory={true} onChange={(v) => { setConfirmPswd(v) }} />
            </div>

            {errorMessage && <div className='error'>{errorMessage}</div>}

            <div className="button-container">
                <NPButton label="Registrati" onClick={onRegister} />
            </div>

            {loading &&
                <div className="overlaid-loader-container">
                    <PleggitAnimatedLoader />
                </div>
            }

            {registrationOver &&
                <div className="overlaid-loader-container col" style={{ background: "rgba(255, 255, 255, 0.9)" }}>
                    <div className="font-xlarge vspacing primary"><b>Grazie per esserti registrato!</b></div>
                    <div className="font-normal">
                        <p>Ti abbiamo inviato una mail con un <b>link per attivare la tua utenza Pleggit</b>.</p>
                        <p>Attiva la tua utenza e metti in sicurezza il pagamento della tua compravendita!</p>
                    </div>
                </div>
            }
        </div>

    )
}

export default withRouter(withTranslation()(RegistrationScreen));