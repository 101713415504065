import React, { Component } from 'react';
import './DashboardScreen.css';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import withProtection from './hoc/WithProtection';
import withSingleColumnsLayout from './hoc/WithSingleColumnLayout';
import CustomerDashboard from './parts/dashboard/CustomerDashboard';
import { AgencyDashboard } from './parts/dashboard/AgencyDashboard';

const cookies = new Cookies();

function Content() {

    const user = cookies.get('user')

    if (user && user.agencyId) return <CustomerDashboard />

    return <AgencyDashboard />

}

export default withProtection(
    withSingleColumnsLayout(
        withRouter(withTranslation()(Content)),
    )
);
