import React from 'react';
import './ProfileForTxCard.css';
import { withTranslation } from 'react-i18next';
import NPCard from '../NPCard';
import NPLabeledValue from '../../NPLabeledValue';
import { NPLoadingBar } from '../../NPLoadingBar';
import { NPValidationError } from '../../../forms/NPValidationError';
import Cookies from 'universal-cookie';

/**
 * Parameters
 * 
 *  - highlight           : (OPT, default false) highlights this card
 *  - highlightColor      : (OPT, default primary color) color of the highlight
 */
class ProfileForTxCard extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { t } = this.props;

        if (this.props.loading === true) return (
            <NPCard highlight={this.props.highlight ? "top" : null} highlightColor={this.props.highlightColor}>
                <div className="profile-for-tx-card">
                    <div className="card-title font-large">{t('party.type.' + this.props.partyType)}</div>
                    <NPLoadingBar />
                </div>
            </NPCard>
        )

        if (!this.props.profile) return (
            <NPCard highlight={this.props.highlight ? "top" : null} highlightColor={this.props.highlightColor}>
                <div className="profile-for-tx-card">
                    <div className="card-title font-large">{t('party.type.' + this.props.partyType)}</div>
                    <NPValidationError errorMessage="Utente non ancora registrato" />
                    <div className="left" style={{ marginTop: 'var(--default-card-spacing' }}>
                        <NPLabeledValue label="label.email" value={this.props.email} />
                    </div>
                </div>
            </NPCard>
        )

        let left;
        if (this.props.profile.piva) left = (
            <div className="left">
                <NPLabeledValue label="label.legal.name" value={this.props.profile.legalName} marginVertical="6px" />
                <NPLabeledValue label="label.piva" value={this.props.profile.piva} marginVertical="6px" />
            </div>
        )
        else if (this.props.profile.cf) left = (
            <div className="left">
                <NPLabeledValue label="label.first.name" value={this.props.profile.firstName} marginVertical="6px" />
                <NPLabeledValue label="label.cf" value={this.props.profile.cf} marginVertical="6px" />
            </div>
        )

        let right;
        if (this.props.profile.cf) right = (
            <div className="right">
                <NPLabeledValue label="label.last.name" value={this.props.profile.lastName} marginVertical="6px" />
                <NPLabeledValue label="label.profile.creation.date" value={this.props.profile.creationDate} type="date" marginVertical="6px" />
            </div>
        )

        return (
            <NPCard highlight={this.props.highlight ? "top" : null} highlightColor={this.props.highlightColor}>
                <div className="profile-for-tx-card">
                    <div className="card-title font-large">
                        {t('party.type.' + this.props.partyType)} {`${this.props.you === true ? '(tu)' : ''}`}
                    </div>
                    <div className=''>
                        <NPLabeledValue label="label.email" value={this.props.profile.email} />
                    </div>
                    <div className="two-col">
                        {left}
                        {right}
                    </div>
                </div>
            </NPCard>
        )
    }
}

export default withTranslation()(ProfileForTxCard);