import './NPSimpleButton.css'

export default function NPSimpleButton(props) {

    let size = 'm'

    return (
        <div className="np-simple-button" onClick={props.onClick}>
            <div className={`button-image ${size}`}>{props.img}</div>
            <div className="title">{props.title}</div>
        </div>
    )
}