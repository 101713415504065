import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './LoginScreen.css';
import loginBackground from '../images/login-illustration.svg';
import { ReactComponent as GoogleSVG } from '../icons/google.svg';
import { ReactComponent as FBSVG } from '../icons/fb.svg';
import NPButton from '../comp/NPButton';
import Cookies from 'universal-cookie';
import AccessAPI from '../api/AccessAPI';
import { withRouter } from 'react-router';
import { storeUser } from '../util/UserUtil';
import { ReactComponent as LoaderSVG } from '../icons/loader-ball-triangle.svg';
import pushListener from '../evt/PushListener';
import Header from '../comp/Header';
import NPTextInput from '../forms/NPTextInput'
import { ReactComponent as PleggitTitle } from '../images/logo/pleggit-title.svg';
import { ReactComponent as PleggitLogo } from '../images/logo/logo.svg';
import AuthAPI from '../api/AuthAPI';
import Footer from '../Footer';
import { NPLoadingBar } from '../comp/NPLoadingBar';

const cookies = new Cookies();

class LoginScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingFB: true,
            loadingGoogle: true,
            logInOngoing: false
        }

        this.checkSigninStatus = this.checkSigninStatus.bind(this);
        this.clearUserCookie = this.clearUserCookie.bind(this);
        this.getInTheApp = this.getInTheApp.bind(this);
        this.forbidAccess = this.forbidAccess.bind(this);
        this.onLoginClick = this.onLoginClick.bind(this);
    }

    componentDidMount() {
        this.checkSigninStatus();
    }

    async onLoginClick() {

        if (!this.state.username || !this.state.password) {
            this.setState({ error: "Per favore, inserisci username e password" })
            return;
        }

        this.setState({ error: null, logInOngoing: true })

        const result = await new AuthAPI().signIn(this.state.username, this.state.password)

        this.setState({ logInOngoing: false })

        if (!result) return;

        if (result.userExists === false) {
            this.setState({ error: "L'utente non è stato trovato" })
            return;
        }

        if (result.loginSucceeded === false) {
            this.setState({ error: "Username o password errate" })
            return;
        }

        if (result.accessToken) this.getInTheApp(result, "pleggit")

    }

    /**
     * Checks if the user is signed in and in case it is, logs into the site
     */
    checkSigninStatus() {

    }

    /**
     * Alright, you have signed in and are actually authorized to enter, so let's do that! 
     */
    getInTheApp(user, authProvider) {

        console.log("[login] - User is authorized to access!");

        storeUser(user, authProvider);

        // Initialize Push Listener
        pushListener.onLogin(user.userId);

        this.props.history.push('/dashboard');

    }

    /**
     * Forbids access to the user
     */
    forbidAccess(user) {

        console.log("[login] - User is not authorized");

        this.setState({
            authorizationCheckResult: false
        });

        this.clearUserCookie();
    }

    /**
     * Clears the user cookie (removes it)
     */
    clearUserCookie() {

        console.log("[login] - User is NOT authorized to access..");
        console.log("[login] - Clearing the user cookie..");

        cookies.remove('user');
    }

    render() {

        const { t } = this.props;

        let message;
        if (this.state.authorizationCheckResult == false) message = (
            <div className="auth-failed-message complementary">
                {t('login.access.not.authorized')}
            </div>
        );

        let loginText;
        if (!this.state.loadingGoogle) loginText = (
            <div className="login-text">
                {t('login.welcome.label')}<br />
                {t('login.methods.label')}
            </div>
        )

        let loginGoogle;
        if (!this.state.loadingGoogle) loginGoogle = (
            <div className="login-button-container">
                <div className="login-button  focused-easeInOut-motion" onClick={this.signIn}>
                    <div className="icon-container"><GoogleSVG className="icon" /></div>
                    <div className="login-button-text">{t('login.google.label')}</div>
                </div>
            </div>
        )

        let loader;
        if (this.state.loadingGoogle) loader = (
            <div className="loader-container">
                <LoaderSVG />
            </div>
        )

        return (
            <div className="login-screen screen">

                <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                    <div className="login-container">

                        <div className="logo-container">
                            <div className="pleggit-logo" ><PleggitLogo /></div>
                            <div className="pleggit-title" ><PleggitTitle /></div>
                        </div>

                        {this.state.logInOngoing && <NPLoadingBar label="Login in corso.." />}

                        {!this.state.logInOngoing &&
                            <>
                                <div className="input-container">
                                    <NPTextInput label="Indirizzo email" onChange={(value) => { this.setState({ username: value }) }} />
                                    <NPTextInput label="Password" type="password" onChange={(value) => { this.setState({ password: value }) }} onPressEnter={this.onLoginClick} />
                                </div>

                                {this.state.error &&
                                    <div className="error-container">
                                        {this.state.error}
                                    </div>
                                }

                                <div className="button-container">
                                    <NPButton label="Login" onClick={this.onLoginClick} />
                                </div>
                            </>
                        }
                    </div>
                </div>

                <div style={{ display: "flex", flex: 1 }}></div>

                <Footer />

            </div>
        )
    }
}

export default withRouter(withTranslation()(LoginScreen));