import { useEffect, useState } from "react";
import './CustomerDashboard.css';
import TranAPI from "../../../api/TranAPI";
import NPCard from "../../../comp/card/NPCard";
import { CustomerTransactionLegend, CustomerTransactions } from "./CustomerTransactions";
import Cookies from "universal-cookie";
import { CustomerProfile } from "./CustomerProfile";
import { isProfileCompleted } from "../../../util/UserUtil";
import { NPCardMessage } from "../../../comp/card/NPCardMessage";

import {ReactComponent as WarningSVG} from '../../../icons/warning.svg';

const cookies = new Cookies()

export default function CustomerDashboard() {

    const [loading, setLoading] = useState(true)

    const [transactions, setTransactions] = useState()
    const [partyType, setPartyType] = useState()
    const [profileCompleted, setProfileCompleted] = useState()

    const [txMissingIban, setTxMissingIban] = useState()
    const [txMissingPayment, setTxMissingPayment] = useState()

    const user = cookies.get('user');

    /**
     * Retrieve my transactions (as a Customer)
     */
    const reload = async () => {

        setTxMissingIban(null)
        setTxMissingPayment(null)

        setLoading(true);

        const apiResponse = await new TranAPI().getMyTransactions()

        setLoading(false)

        setTransactions(apiResponse.transactions)

        // If there is only ONE transaction, set the party type
        if (apiResponse.transactions && apiResponse.transactions.length == 1) setPartyType(apiResponse.transactions[0].buyerEmail == user.email ? "buyer" : "seller");

        onTransactionsLoaded(apiResponse.transactions)
    }

    /**
     * Reacts to the list of transactions being loaded. 
     * 
     * 1. If there are transactions for which the user is the SELLER and IBAN is missing from those transactions, mark the status as "Need to insert IBAN for transaction XX"
     * 
     * 2. Otherwise, if there are transactions for which the user is the BUYER, tell the user that they need to perform the transfer
     * 
     * @param {list} transactions List of Transactions
     */
    const onTransactionsLoaded = (transactions) => {

        if (!transactions || transactions.length == 0) return;

        let firstBuyerTxMissingPayment = null

        for (let tx of transactions) {

            // If the user is the seller and the tx does not have an IBAN
            if (tx.sellerEmail == user.email && !tx.iban) {
                setTxMissingIban(tx);
                break;
            }

            // If the user is a buyer and the tx has not received the payment
            if (tx.buyerEmail == user.email && !tx.buyerPaid && !firstBuyerTxMissingPayment) {
                firstBuyerTxMissingPayment = tx;
            }
        }

        setTxMissingPayment(firstBuyerTxMissingPayment);

    }

    /**
     * Reacts to the profile being loaded. 
     *  - Check for the profile completion
     * 
     * @param {object} profile The User Profile
     */
    const onProfileLoaded = (profile) => {

        setProfileCompleted(isProfileCompleted(profile));

    }

    useEffect(() => { reload() }, [])

    return (
        <div className="screen customer-dashboard">

            <NPCard>
                <div className="card-title">Benvenuta/o su Pleggit.</div>
                <div>
                    {!loading && !profileCompleted &&
                        <>
                            Grazie per esserti registrato! <br />
                            <p>Prima di proseguire con la compravendita, devi <b>completare il tuo Profilo</b> (qui sotto) e accettare i Termini di Utilizzo di Pleggit.</p>
                            Per completa transparenza, puoi vedere i prezzi Pleggit sulla nostra <b><a target="_blank" href="https://pleggit.com/pricing">Pagina di Pricing</a></b>: la commissione Pleggit serve a garantire la sicurezza della tua transazione!
                        </>
                    }
                    {profileCompleted && (txMissingIban || txMissingPayment) &&
                        <>
                            <NPCardMessage text="Alcune transazioni necessitano del tuo intervento" color="complementary" />
                            <div className="customer-warning-text">Verifica le transazioni con l'icona <WarningSVG/> nella lista qui sotto.</div>
                            <div>Sono transazioni di compravendita che <b>necessitano del tuo intervento</b> per poter proseguire. </div>
                        </>
                    }
                    {profileCompleted && !txMissingIban && !txMissingPayment && <p>In questa pagina puoi verificare lo stato delle tue transazioni in corso.</p>}
                </div>
            </NPCard>

            <NPCard>
                <CustomerProfile partyType={partyType} onProfileLoaded={onProfileLoaded} />
            </NPCard>

            {profileCompleted &&
                <NPCard>
                    <div className="card-header">
                        <div className="card-title">Le tue transazioni</div>
                        <div className="button-container">
                            <CustomerTransactionLegend />
                        </div>
                    </div>
                    <div className="card-body">
                        <CustomerTransactions transactions={transactions} loading={loading} />
                    </div>
                </NPCard>
            }
        </div>
    )

}

