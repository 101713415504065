import NPAPI from './NPAPI';

export default class InfoAPI {

  getPaymentInfo(code) {

    return new NPAPI().fetch('info', `/pcodes/${code}/info`).then((response) => response.json());

  }

}
