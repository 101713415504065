import './NPLoadingBar.css';

export function NPLoadingBar({ label }) {

    const loadingLabel = label ? label : "Caricamento in corso.. ";

    return (
        <div className="np-loading-bar">
            <div className='label'>{loadingLabel}</div>
            <div className="progress">
            </div>
        </div>
    )
}