import { useEffect, useState } from "react";
import NPCard from "./NPCard";
import './NPNotificationCard.css';

/**
 * Displays a "Notification" Card. 
 * For example can be used for messages like "The Transaction has been successfully created".
 */
export function NPNotificationCard({ message, icon, trigger }) {

    const [visible, setVisible] = useState(trigger)

    return (
        <>
            {visible &&
                <div className="np-notification-card-container">
                    <NPCard>
                        <div className="np-notification-card">
                            <div className="icon">{icon}</div>
                            <div className="msg">{message}</div>
                        </div>
                    </NPCard>
                </div>
            }
        </>
    )

}