import './TransactionStateIcon.css'
import { ReactComponent as CarSVG } from '../icons/car.svg'
import { ReactComponent as HandsSVG } from '../icons/handshake.svg'
import { ReactComponent as KeySVG } from '../icons/car-key.svg'
import { ReactComponent as WarnSVG } from '../icons/warning.svg';
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export function TransactionStateIcon({ transaction }) {

    const loggedUser = cookies.get("user")

    if (!loggedUser) return;

    // Determine if a warning is needed: 
    let warningNeeded = false;
    // If the user is the buyer and the money has not been wired yet
    if (!transaction.buyerPaid && loggedUser.email == transaction.buyerEmail) warningNeeded = true;
    // If the user is the seller and the iban is not present
    if (!transaction.iban && loggedUser.email == transaction.sellerEmail) warningNeeded = true;

    let transactionState = "waitingForBuyerPayment"
    if (transaction.buyerPaid === true) transactionState = "readyForPT"
    if (transaction.buyerPaid === true && transaction.propertyTransferred === true) transactionState = "ptDone"
    if (transaction.buyerPaid === true && transaction.propertyTransferred === true && transaction.sellerWireDone === true) transactionState = "closed"
    // Override: 
    if (warningNeeded) transactionState = "warning";

    return (
        <div className="tx-state-icon">
            {transactionState == 'readyForPT' && <CarSVG />}
            {transactionState == 'ptDone' && <KeySVG />}
            {transactionState == 'closed' && <HandsSVG />}
            {transactionState == 'warning' && <span style={{ color: "var(--color-complementary)", display: "flex" }}><WarnSVG /></span>}
        </div>
    )

}