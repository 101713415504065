import { useState } from "react";
import { NPExpander } from "../../../comp/NPExpander";
import NPLabeledValue from "../../../comp/NPLabeledValue";
import './CustomerProfile.css';

import { ReactComponent as ArrowUp } from '../../../icons/arrow-up.svg';

export function CustomerProfileData({ profile }) {

    const [expand, setExpand] = useState(false);

    return (
        <div className="customer-profile-data">
            {profile.cf &&
                <>
                    <div className="row">
                        <NPLabeledValue label="Codice Fiscale" value={profile.cf} transform="uppercase" />
                        <NPLabeledValue label="Nome" value={profile.firstName} transform="capitalize" />
                        <NPLabeledValue label="Cogome" value={profile.lastName} transform="capitalize" />
                    </div>

                    {!expand && <div style={{ marginTop: 12 }}><NPExpander onClick={() => { setExpand(true) }} /></div>}

                    {expand &&
                        <>
                            <div className="row spaced">
                                <NPLabeledValue label="Indirizzo di residenza" value={profile.residenceAddress} transform="capitalize" />
                                <NPLabeledValue label="CAP" value={profile.residenceCAP} />
                                <NPLabeledValue label="Città" value={profile.residenceCity} transform="capitalize" />
                            </div>
                            <div className="row spaced">
                                <NPLabeledValue label="Provincia" value={profile.residenceProvince} transform="uppercase" />
                                <NPLabeledValue label="Paese" value={profile.residenceCountry} transform="uppercase" />
                                <div style={{ flex: 1 }}></div>
                            </div>

                            <div className="dexpander" onClick={() => { setExpand(false) }}>
                                <ArrowUp />
                            </div>
                        </>
                    }
                </>
            }
            {profile.piva &&
                <>
                    <div className="row">
                        <NPLabeledValue label="Partiva Iva" value={profile.piva} transform="uppercase" />
                        <NPLabeledValue label="Ragione Sociale" value={profile.legalName} transform="capitalize" />
                        <div></div>
                    </div>

                    {!expand && <div style={{ marginTop: 12 }}><NPExpander onClick={() => { setExpand(true) }} /></div>}

                    {expand &&
                        <>
                            <div className="row spaced">
                                <NPLabeledValue label="Indirizzo sede legale" value={profile.regOfficeAddress} transform="capitalize" />
                                <NPLabeledValue label="CAP" value={profile.regOfficeCAP} />
                                <NPLabeledValue label="Città" value={profile.regOfficeCity} transform="capitalize" />
                            </div>
                            <div className="row spaced">
                                <NPLabeledValue label="Provincia" value={profile.regOfficeProvince} transform="uppercase" />
                                <NPLabeledValue label="Paese" value={profile.regOfficeCountry} transform="uppercase" />
                                <div style={{ flex: 1 }}></div>
                            </div>

                            <div className="dexpander" onClick={() => { setExpand(false) }}>
                                <ArrowUp />
                            </div>
                        </>
                    }
                </>
            }
        </div>
    )
}