import { useEffect, useState } from 'react'
import '../CustomerProfile.css'
import { NPRadioButtons } from '../../../../forms/NPRadioButtons'
import NPTextInput from '../../../../forms/NPTextInput'
import { NPCheckBox } from '../../../../comp/NPCheckBox'
import NPButton from '../../../../comp/NPButton'
import { NPValidationError } from '../../../../forms/NPValidationError'

const userTypeOptions = [
    { code: "private", label: "Privato" },
    { code: "company", label: "Azienda" }
]

const links = [
    { label: "Termini di Utilizzo", link: "https://pleggit.com/terms" },
    { label: "Policy sulla Privacy", link: "https://pleggit.com/privacy" },
]

export function FormStep1({ onCompleted }) {

    const [userType, setUserType] = useState()
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [cf, setCf] = useState()
    const [piva, setPiva] = useState()
    const [legalName, setLegalName] = useState()
    const [termsAccepted, setTermsAccepted] = useState(false)
    const [error, setError] = useState(null)

    const validate = () => {

        let err = null;

        if (!termsAccepted) err = "Devi accettare i Termini di Utilizzo e il consenso al Trattamento dei Dati per proseguire."

        if (!userType) err = "Seleziona il tipo di utenza per proseguire."

        if (userType && userType.code == 'private') {
            if (!cf) err = "Inserisci il tuo Codice Fiscale per proseguire."
            if (cf && cf.length != 16) err = "Il Codice Fiscale deve contenere 16 caratteri."
            if (!firstName || !lastName) err = "Inserisci il tuo nome e cognome per proseguire."
        }
        else if (userType && userType.code == 'company') {
            if (!piva) err = "Inserisci la tua Partita Iva per proseguire"
            if (!legalName) err = "Inserisci la ragione sociale della tua azienda per proseguire"
        }

        setError(err);

        return err == null

    }

    const save = async () => {

        if (!validate()) return;

        onCompleted({ userType: userType.code, firstName, lastName, cf, piva, legalName, termsAccepted })
    }

    /**
     * When the user type changes, make sure to clear out the fields
     */
    useEffect(() => {

        setCf(null)
        setFirstName(null)
        setLastName(null)
        setPiva(null)
        setLegalName(null)

    }, [userType])

    return (
        <div className="form">
            {error && <div style={{marginBottom: "var(--default-card-spacing)"}}><NPValidationError errorMessage={error} /></div>}
            <div className="row mobile-col">
                <div className="col">
                    <NPRadioButtons label="Seleziona il tipo di utenza" options={userTypeOptions} selectedOption={userType} onSelectOption={(o) => setUserType(o)} />
                </div>
                <div className="col">
                    {(userType == null || userType.code == 'private') && <NPTextInput minLength={16} maxLength={16} transform="uppercase" label="Codice Fiscale" mandatory={true} onChange={(v) => { setCf(v) }} />}
                    {(userType && userType.code == 'company') && <NPTextInput label="Partita Iva" mandatory={true} onChange={(v) => { setPiva(v) }} />}

                </div>
            </div>
            {(userType == null || userType.code == 'private') &&
                <>
                    <div className="row mobile-col">
                        <div className="col">
                            <NPTextInput label="Nome" mandatory={true} onChange={(v) => { setFirstName(v) }} />
                        </div>
                        <div className="col">
                            <NPTextInput label="Cognome" mandatory={true} onChange={(v) => { setLastName(v) }} />
                        </div>
                    </div>
                </>
            }
            {(userType && userType.code == 'company') &&
                <>
                    <div className="row mobile-col">
                        <NPTextInput label="Ragione Sociale" mandatory={true} onChange={(v) => { setLegalName(v) }} />
                    </div>
                </>
            }
            <div style={{ marginTop: 12 }}></div>
            <div className="row">
                <div className="col">
                    <NPCheckBox ticked={termsAccepted} label="Accetto i Termini di Utilizzo di Pleggit e al trattamento dei Dati Personali riportati qui sotto" links={links} onClick={() => { setTermsAccepted(!termsAccepted) }} />
                </div>
            </div>
            <div style={{ marginTop: 24 }}></div>
            <div className="buttons-container">
                <div style={{ flex: 1 }}></div>
                <NPButton label="Prosegui" onClick={save} />
            </div>
        </div>
    )

}