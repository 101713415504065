import React from 'react';
import Cookies from 'universal-cookie';
import img404 from '../../images/notfound-illustration.svg';
import './WithProtection.css';
import NPButton from '../../comp/NPButton';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

const cookies = new Cookies();

const protectedRoutes = ['admin'];

export default function withProtection(ComponentToProtect) {

    return withRouter(withTranslation()(class extends React.Component {

        render() {

            let protectedRes = false;
            for (let i = 0; i < protectedRoutes.length; i++) {
                if (this.props.location.pathname.indexOf(protectedRoutes[i]) > 0) { protectedRes = true; break; }
            }

            // Before rendering, check if the user is actually authorized to access this URL
            if (cookies.get('user')) {

                // If the user is admin, it can only go to the admin route
                // So: If the user is an admin and the route is an admin route (protected resource) then go there
                if (cookies.get('user').role == 'admin' && protectedRes) {
                    return (
                        <ComponentToProtect {...this.props} />
                    )
                }
                // If the user is an admin and the route is unprotected, redirect to the admin route
                if (cookies.get('user').role == 'admin' && !protectedRes) {
                    this.props.history.push('/admin');
                    return (<div></div>);
                }

                // If the route is not proteced => ok
                if (!protectedRes) {
                    return (
                        <ComponentToProtect {...this.props} />
                    )
                }

                
            }

            const { t } = this.props;

            return (
                <div className="four-o-four">
                    <div className="image">
                        <img src={img404} width="100%" />
                    </div>
                    <div className="text-container">
                        <div className="font-righteous font-xxxlarge">
                            {t('notfound.sorry.text')}
                        </div>
                        <div className="font-righteous font-xlarge">
                            {t('notfound.or.text')}
                        </div>
                        <div style={{ marginTop: '42px', marginBottom: '14px' }}>
                        </div>
                        <div>
                            <NPButton label="Log in" size='large' raised={true} onClick={() => { this.props.history.push('/login') }} />
                        </div>
                    </div>
                </div>
            )
        }
    }))

}