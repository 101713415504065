import Cookies from "universal-cookie";
import './CustomerProfile.css';
import { useEffect, useState } from "react";
import { ReactComponent as TickSVG } from '../../../icons/tick.svg';
import { FormStep1 } from "./form/CustomerForm1";
import { FormStep2 } from "./form/CustomerForm2";
import { CustomerFormFinished } from "./form/CustomerFormFinished";
import ProfileAPI from "../../../api/ProfileAPI";
import { NPLoadingBar } from "../../../comp/NPLoadingBar";
import { CustomerProfileData } from "./CustomerProfileData";
import { isProfileCompleted } from "../../../util/UserUtil";

const cookies = new Cookies()

export function CustomerProfile({ partyType, onProfileCompleted, onProfileLoaded }) {

    const [profileCompleted, setProfileCompleted] = useState(null)
    const [profileData, setProfileData] = useState()

    const [loading, setLoading] = useState(false)

    const loadProfile = async () => {

        const timer = setTimeout(() => { setLoading(true) }, 500)

        const apiResponse = await new ProfileAPI().getProfileData()

        clearTimeout(timer)
        setLoading(false)

        // Check if there is a profile
        if (Object.keys(apiResponse).length > 0) {

            // Check if all the data is there and hence if the profile is completed
            setProfileCompleted(isProfileCompleted(apiResponse))

            // Set the profile data
            setProfileData(apiResponse)

            // If a callback has been configured, pass the profile
            if (onProfileLoaded) onProfileLoaded(apiResponse)
        }
        else setProfileCompleted(false)

    }

    useEffect(() => { loadProfile() }, [])

    return (
        <div className="customer-profile">

            <div className="card-title">{profileCompleted == false ? "Completa il tuo Profilo Pleggit" : "Il tuo Profilo Pleggit"}</div>

            {loading && <NPLoadingBar label="Caricamento Profilo in corso.." />}

            {!loading && profileCompleted == false &&
                <>
                    <div>I dati richiesti in questa sezione sono necessari a Pleggit per l'emissione della fattura.  </div>
                    <div style={{ margin: 24 }}></div>
                </>
            }

            {!loading && profileCompleted == false && <CustomerForm partyType={partyType} profileData={profileData} onFinished={() => { loadProfile(); if (onProfileCompleted) onProfileCompleted() }} />}

            {!loading && profileCompleted && <CustomerProfileData profile={profileData} />}

        </div>
    )

}

function CustomerForm({ partyType, onFinished, profileData }) {

    const steps = 2;

    const [currentStep, setCurrentStep] = useState(null);
    const [profile, setProfile] = useState({})

    const user = cookies.get('user');

    const init = () => {
        if (!profileData) setCurrentStep(0)
        else {
            if (profileData.cf || profileData.piva) setCurrentStep(1)
        }
    }

    const onStepCompleted = (stepProfileData) => {

        // Save the data 
        const updatedProfile = {
            ...profile,
            ...stepProfileData
        }

        setProfile(updatedProfile)

        // Move to the next step
        let nextStep = currentStep + 1

        if (nextStep > steps - 1) nextStep = steps;

        setCurrentStep(nextStep);

        // Create the profile data for the API
        const profileData = {
            userType: stepProfileData.userType, 
            cf: updatedProfile.cf,
            firstName: updatedProfile.firstName,
            lastName: updatedProfile.lastName,
            residenceAddress: updatedProfile.residenceAddress,
            residenceCAP: updatedProfile.residenceCAP,
            residenceCity: updatedProfile.residenceCity,
            residenceProvince: updatedProfile.residenceProvince,
            residenceCountry: updatedProfile.residenceCountry,

            acceptedPrivacy: updatedProfile.termsAccepted,
            acceptedTerms: updatedProfile.termsAccepted,
            acceptedSpecialTerms: updatedProfile.termsAccepted,

            piva: updatedProfile.piva,
            legalName: updatedProfile.legalName,
            regOfficeAddress: updatedProfile.regOfficeAddress,
            regOfficeCAP: updatedProfile.regOfficeCAP,
            regOfficeCity: updatedProfile.regOfficeCity,
            regOfficeProvince: updatedProfile.regOfficeProvince,
            regOfficeCountry: updatedProfile.regOfficeCountry,

            agencyId: user.agencyId,
        }

        console.log("Profile Data being saved: ");
        console.log(profileData);

        // Save the profile data
        new ProfileAPI().updateProfile(profileData)

    }

    useEffect(init, [])

    return (
        <div className="customer-form">

            {currentStep < steps &&
                <>
                    <FormSteps steps={steps} currentStep={currentStep} />

                    <div style={{ marginTop: 32 }}></div>
                </>
            }

            {currentStep == 0 && <FormStep1 onCompleted={onStepCompleted} />}
            {currentStep == 1 && <FormStep2 onCompleted={onStepCompleted} userType={profile ? profile.userType : null} />}
            {currentStep == steps && <CustomerFormFinished partyType={partyType} onClose={onFinished} />}

        </div>
    )
}

function FormSteps({ steps, currentStep }) {

    return (
        <div className="form-steps">
            {steps && [...Array(steps).keys()].map((step) => <FormStep key={Math.random()} step={{ step: step + 1, completed: currentStep > step, current: currentStep == step, last: step == steps - 1 }} />)}
        </div>
    )
}

function FormStep({ step }) {

    return (
        <div className={`form-step ${step.completed ? "completed" : ""} ${step.current ? "current" : ""} ${step.last ? "last" : ""}`}>
            <div className={`number`}>
                {!step.completed && step.step}
                {step.completed && <TickSVG />}
            </div>
            <div className={`line`}></div>
        </div>
    )
}