import { toHaveStyle } from '@testing-library/jest-dom/dist/matchers';
import { Component } from 'react';
import './NPButton.css';

/**
 * Generic button
 * 
 * Properties: 
 * 
 *  - label                 :   (MAND) Label of the button
 *  - raised                :   (OPT, default false) Set to true if the button should be raised
 *  - filled                :   (OPT, default true) Set to false if the button shouldn't have a background (not filled with color)
 *  - outline               :   (OPT, default false) Set to true to show the shape of the button. Typically used with filled=false to show an outlined button
 *  - outlineLight          :   (OPT, default false) Set to true to show the shape of the button in white. Typically used with filled=false to show an outlined button
 *  - size                  :   (OPT, default null) Size of the button. 
 *                              The default size is considered to be 'medium'
 *                              The allowed sizes are, currently: ('medium', 'large', 'small)
 *  - secondary             :   (OPT, default false) Set to true to style it as a secondary button
 *  - highlighted           :   (OPT, default false) if true, sets this button to be highlighted (kind of selected)
 * 
 * Listeners:
 * 
 *  - onClick               :   (OPT) called when the button is clicked
 */
export default class NPButton extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pressed: false,
            released: false
        }

        this.onClick = this.onClick.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);

    }

    onMouseDown() {
        this.setState({
            pressed: true,
            released: false
        })
    }

    onMouseUp(event) {

        // Let's start the ripple! 
        // 1. Get the mouse coordinates relative to the button: that's where the ripple is going to start from! 
        // 1.1 Get the button
        let button = event.target;
        let i = 0;
        while (!button.classList.contains('np-button') && i < 5) {
            button = button.parentNode;
            i++;
        }

        // 1.2 Get the button coordinates
        let buttonCoord = { x: button.getBoundingClientRect().left, y: button.getBoundingClientRect().top };

        // 1.3 Get the mouse coordinates
        let mouseCoord = { x: event.clientX, y: event.clientY };

        // 1.4. Get the mouse coord relative to the button
        let mouseRelativeCoord = { x: mouseCoord.x - buttonCoord.x, y: mouseCoord.y - buttonCoord.y };

        // 2. Define the ripple coordinates
        // The ripple should have its top left corner where the mouse is, but should rather have its center there
        // so the ripple top left coord will have to be shifted of half its width and height
        // The ripple size is going to be: 
        //   - width: half the width of the button 
        //   - height: twice the height of the button
        let rippleSize = { width: button.offsetWidth / 2, height: button.offsetHeight * 2 };
        let rippleCoord = { x: mouseRelativeCoord.x - rippleSize.width / 2, y: mouseRelativeCoord.y - rippleSize.height / 2 };

        this.setState({
            pressed: false,
            released: true,
            rippleCoord: rippleCoord,
            rippleSize: rippleSize
        })
    }

    onClick() {
        if (this.props.onClick) this.props.onClick();
    }

    render() {
        let buttonClass = "np-button focused-easeInOut-motion";
        if (this.props.color) buttonClass += ' ' + this.props.color;
        if (this.props.size) buttonClass += ' ' + this.props.size;
        if (this.props.raised) buttonClass += ' with-shadow';
        if (this.props.filled == null || this.props.filled == true) buttonClass += ' fill-accent light-background rises';
        if (this.props.filled == false) buttonClass += ' nofill';
        if (this.props.outline) buttonClass += ' outline accent';
        if (this.props.outlineLight) buttonClass += ' outline light';
        if (this.props.secondary) buttonClass += ' secondary';
        if (this.props.warning) buttonClass += ' warning';
        
        let ripple;
        if (this.state.released) ripple = (
            <div className="ripple" style={{ left: this.state.rippleCoord.x, top: this.state.rippleCoord.y, width: this.state.rippleSize.width, height: this.state.rippleSize.height }}></div>
        )

        return (
            <div className={buttonClass} onClick={this.onClick} onMouseDown={this.onMouseDown} onMouseUp={this.onMouseUp}>
                <div className="button-title">
                    {this.props.label}
                </div>
                {ripple}
            </div>
        )
    }
}