import { useState } from 'react'
import NPCard from '../NPCard'
import './AgencyPropertyTransferCard.css'
import { NPCardMessage } from '../NPCardMessage'
import { NPSquareButton } from '../../NPSquareButton'
import { ReactComponent as ButtonIconSVG } from '../../../icons/car-key.svg';
import TranAPI from '../../../api/TranAPI'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export function AgencyPropertyTransferCard({ tx, onPropertyTransferred }) {

    const [transaction, setTransaction] = useState(tx)
    const [visible, setVisible] = useState(true)

    const loggedUser = cookies.get('user');


    /**
     * Mark the property as transferred
     */
    const postPropertyTransferred = async () => {

        setVisible(false)

        await new TranAPI().postPropertyTransferred(transaction.id);

        if (onPropertyTransferred) onPropertyTransferred();

    }

    /**
     * Checks if the logged user is the Agency
     * 
     * @returns true is the logged user if the agency
     */
    const loggedUserIsAgency = () => {
        return loggedUser.email != transaction.sellerEmail && loggedUser.email != transaction.buyerEmail;
    }

    return (
        <NPCard>
            <div className='agency-pt-card row'>
                <NPCardMessage icon={tx && tx.propertyTransferred === true ? "ok" : null} date={tx.propertyTransferredOn} text={tx && tx.propertyTransferred === true ? "Il Passaggio di Proprietà è stato effettuato." : "Puoi effettuare il Passaggio di Proprietà per questo veicolo."} />
                {!tx.propertyTransferred && visible && loggedUserIsAgency() &&
                    <>
                        <div className="spacer"></div>
                        <NPSquareButton icon={<ButtonIconSVG />} label="Il Passaggio di Proprietà è stato effettuato" onClick={postPropertyTransferred} />
                    </>
                }
            </div>
        </NPCard>
    )
}