import Cookies from 'universal-cookie';

const cookies = new Cookies();

/**
 * Returns the token associated with the user
 */
export function getUserToken() {

    return new Promise((success, failure) => {

        const user = cookies.get('user')

        if (!user || !user.idToken) {
            success(null)
        }

        success(user.idToken);
    })
}