import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './PaymentInfoScreen.css';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router';
import { ReactComponent as PleggitTitle } from '../images/logo/pleggit-title.svg';
import { ReactComponent as PleggitLogo } from '../images/logo/logo.svg';
import NPLabeledValue from '../comp/NPLabeledValue';
import CompanyAPI from '../api/CompanyAPI';
import InfoAPI from '../api/InfoAPI';

const cookies = new Cookies();

class PaymentInfoScreen extends Component {

    constructor(props) {
        super(props);

        const rcode = this.props.location.pathname.substring("/plink/".length);
        const licensePlates = rcode.substring(0, rcode.indexOf('-'))

        this.state = {
            rcode: rcode,
            companyInfo: {},
            licensePlates: licensePlates,
        }

        this.getCompanyInfo = this.getCompanyInfo.bind(this);
        this.getPaymentInfo = this.getPaymentInfo.bind(this);

    }

    componentDidMount() {

        this.getCompanyInfo();
        this.getPaymentInfo();
    }

    async getCompanyInfo() {

        const info = await new CompanyAPI().getCompanyInfo();

        this.setState({ companyInfo: info })
    }

    /**
     * Calculates the full price that the Buyer needs to send
     * @param {object} info the Payment Info object
     */
    calculateFullPrice(info) {

        let fullPrice = parseFloat(info.price) + parseFloat(info.fees);

        if (info.agencyFees) fullPrice += parseFloat(info.agencyFees);

        return fullPrice;

    }

    async getPaymentInfo() {

        const info = await new InfoAPI().getPaymentInfo(this.state.rcode);

        if (info) {
            this.setState({
                fees: info.fees,
                licensePlates: info.licensePlates,
                price: info.price,
                fees: info.fees,
                agencyFees: info.agencyFees, 
                fullPrice: this.calculateFullPrice(info),
                rcode: info.reason
            })
        }
    }

    asCurrency(amount) {
        if (amount) return parseFloat(amount).toLocaleString('it-IT', { style: 'currency', currency: "EUR", minimumFractionDigits: 2 })
        
        return parseFloat("0").toLocaleString('it-IT', { style: 'currency', currency: "EUR", minimumFractionDigits: 2 })
    }

    render() {

        return (
            <div className="pinfo-screen screen">

                <div className="pinfo-container">

                    <div className="agency-logo-container">
                        <img src={require('../images/agencies/marinoni.png')} />
                    </div>
                    <div className="logo-container">
                        <div className="pleggit-logo" ><PleggitLogo /></div>
                        <div className="pleggit-title" ><PleggitTitle /></div>
                    </div>

                    <div className="info-container">
                        <div className="tx-intro">Transazione di compravendita del veicolo immatricolato</div>
                        <div className="license-plates">{this.state.licensePlates}</div>
                        <div className="pay-intro">
                            <p>Di seguito troverai i dati per effettuare il bonifico per l'acquisto del veicolo {this.state.licensePlates}.</p>
                            <p>Una volta effettuato il bonifico sarà possibile recarti in agenzia per effettuare il passaggio di proprietà, nella data stabilita.</p>
                        </div>
                        <div className="pay-data">
                            <NPLabeledValue label="Destinatario" value={this.state.companyInfo.legalName} copy={true} size="large" />
                            <NPLabeledValue label="IBAN destinatario" value={this.state.companyInfo.iban} copy={true} size="large" />
                            <NPLabeledValue label="Causale da riportare nel bonifico" value={this.state.rcode} copy={true} size="large" />
                            <NPLabeledValue label="Importo da bonificare (inclusivo delle commissioni Pleggit e di Agenzia)" value={this.state.fullPrice ? this.state.fullPrice.toLocaleString('it-IT', { style: 'currency', currency: "EUR", minimumFractionDigits: 2 }) : null} size="large" />
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

export default withRouter(withTranslation()(PaymentInfoScreen));