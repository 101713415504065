import React from 'react';
import './NPOnOff.css';
import {ReactComponent as ClearSVG} from '../icons/eraser.svg';

export default class NPOnOff extends React.Component {

    constructor(props) {
        super(props);

        this.options = [];
        this.optionLabels = ['0', (<div className="icon-container"><ClearSVG/></div>), '1'];

        this.selectOption = this.selectOption.bind(this);
    }

    componentDidMount() {
        
        // Preselect an option
        this.selectOption(this.props.default ? this.props.default : 1);

        // Set the size of the floating button
        this.floatingButton.style.width = this.options[0].clientWidth + 'px';
        this.floatingButton.style.height = this.options[0].clientHeight + 'px';
    }

    /**
     * Selects option x
     * @param {int} option the selected option number
     */
    selectOption(option) {

        // Change the selection
        this.setState({
            selectedOption: option
        })

        // Move the floating button
        let left; 
        left = this.options[option].offsetLeft;
            
        this.floatingButton.style.left = left + 'px';

        // Update caller if any
        if (this.props.onChange) this.props.onChange(option);

    }

    render() {

        return (
            <div className="np-on-off">
                <div className="radio-container">
                    {
                        this.optionLabels.map((option, i) => {
                            return (
                                <div key={"option" + i} className="option-label" ref={(el) => { this.options.push(el) }} onClick={() => { this.selectOption(i) }}>{option}</div>
                            )
                        })
                    }
                    <div className="floating-button fill-accent focused-easeInOut-motion" ref={(el) => { this.floatingButton = el; }}></div>
                </div>
            </div>
        )
    }
}