import NPEventBus from './NPEventBus';
import Cookies from 'universal-cookie';

class PushListener {

    constructor() {

        // 1. Instantiate pusher
        this.pusher = new window.Pusher(process.env.REACT_APP_PUSHER_KEY, {
            cluster: 'eu'
        });

        // 2. Check if there is a user in the cookies: if there is, subscribe to its notifications channel
        let user = new Cookies().get('user');

        if (!user || !user.userId) return;

        this.subscribeToNotifications(user.userId);

    }

    subscribeToNotifications(userId) {

        this.channel = this.pusher.subscribe('profile-' + userId);
    
        this.channel.bind('profile-event', function (data) {
            
            console.log("[Push Listener] - Received the following profile event: " + data.eventType);
            
            NPEventBus.publishEvent({name: "profile-" + data.eventType, context: data});
    
        });

        console.log("[Push Listener] - Successfully subscribed to user notifications!");
    }

    /**
     * Subscribe to notifications on this transaction.
     * Note that one can only subscribe to events on one transaction at a time! 
     * @param {string} transactionId the tid
     */
    subscribeToTransactionNotifications(transactionId) {

        // Start by cleaning the subscription, to avoid duplicate events
        if (this.txChannel) this.txChannel.unbind();

        this.txChannel = this.pusher.subscribe('transaction-' + transactionId);

        console.log("[Push Listener] - Subscribed to channel transaction-" + transactionId);

        this.txChannel.bind('transaction-event', function(data) {
            
            console.log("[Push Listener] - Received the following transaction event: " + data.eventType);
            
            NPEventBus.publishEvent({name: "transaction-" + data.eventType, context: data});

        })

    }

    /**
     * When the user has logged in, subscribe to its channel
     */
    onLogin(userId) {

        if (!userId) {
            console.error("[Push Listener] - ERROR - No user id passed to onLogin()!");
            return;
        }

        this.subscribeToNotifications(userId);

        console.log("[Push Listener] - Successfully logged in the user!");

    }

    /**
     * When the user logs out, unsubscribe
     */
    onLogout(userId) {

        if (!userId) {
            console.error("[Push Listener] - ERROR - No user id passed to onLogout()!");
            return;
        }

        this.pusher.unsubscribe('profile-' + userId);

        console.log("[Push Listener] - Successfully logged out the user!");
    }

}

function loadPusher() {

    if (window.Pusher == undefined) {
        setTimeout(loadPusher, 100);
        console.log("Waiting for Pusher to be loaded");
        return;
    }

    console.log("Pusher has been loaded!");

}

loadPusher();

export default new PushListener();