import React, { Component } from 'react';
import './UniqueCodeScreen.css';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import withProtection from './hoc/WithProtection';
import withSingleColumnsLayout from './hoc/WithSingleColumnLayout';
import NPCard from '../comp/card/NPCard';
import NPTextInput from '../forms/NPTextInput';
import NPButton from '../comp/NPButton';
import NPLabeledValue from '../comp/NPLabeledValue';
import TranAPI from '../api/TranAPI';

const cookies = new Cookies();

class UniqueCodeScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

        this.onClose = this.onClose.bind(this);
        this.loadPaymentLink = this.loadPaymentLink.bind(this);

    }

    componentDidMount() {
        this.loadPaymentLink();
    }

    async loadPaymentLink() {

        const result = await new TranAPI().getPaymentLink(this.props.location.state.tid); 

        if (result.paymentLink) this.setState({paymentLink: result.paymentLink})
    }

    onClose() {
        this.props.history.push("/dashboard")
    }

    render() {

        return (
            <div className="ucs-screen screen">

                <NPCard>
                    <div className="card-title">
                        La transazione è stata registrata!
                    </div>
                    <div className="instructions">
                        Invia al Compratore il seguente link, che contiene i dati univoci per effettuare il bonifico relativo a questa transazione. 
                    </div>
                    <div className="unique-link">
                        <NPLabeledValue 
                            label="Link da inviare al compratore" 
                            value={this.state.paymentLink}
                            size="medlarge"
                            copy={true}
                            />
                    </div>
                    <div className="buttons-container">
                        <NPButton label="Chiudi" onClick={this.onClose}/>
                    </div>
                </NPCard>

            </div>
        )
    }
}

export default withProtection(
    withSingleColumnsLayout(
        withRouter(withTranslation()(UniqueCodeScreen))
    )
);