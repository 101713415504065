import React from 'react';
import { withRouter } from 'react-router';
import './NPBreadCrumb.css';

class NPBreadCrumb extends React.Component {

    constructor(props) {
        super(props);

        this.createBreadcrumb = this.createBreadcrumb.bind(this);
        this.navigatoTo = this.navigatoTo.bind(this);
    }

    navigatoTo(el) {

        let target = this.props.location.pathname.substring(0, this.props.location.pathname.indexOf(el) + el.length);

        this.props.history.push(target);
    }

    createBreadcrumb() {

        let pathElements = this.props.location.pathname.split('/');

        if (pathElements[0] === "") pathElements.splice(0, 1);

        // If there's only one element, don't show the bread crumb
        if (pathElements.length == 1) return null;

        let crumb = [];

        for (let i = 0; i < pathElements.length; i++) {

            let el = pathElements[i];

            let crumbClass = 'crumb font-small';
            if (i == pathElements.length - 1) crumbClass += ' fill-primary dark-background bold';
            else crumbClass += ' fill-accent focused-easeInOut-motion';

            let comp = (<div key={"bc-el-" + i} className={crumbClass} onClick={() => {this.navigatoTo(el)}}>{el}</div>)

            let separator = (<div></div>);
            if (i < pathElements.length - 1) separator = (<div key={"bc-sep-" + i} className="bc-separator">/</div>)

            let couple = (
                <div key={'bc-cont-' + i} className="crumb-container">
                    {comp}
                    {separator}
                </div>
            )

            crumb.push(couple);
        } 

        return crumb;

    }

    render() {

        let breadcrumb = this.createBreadcrumb();

        return (
            <div className="np-bread-crumb">
                {breadcrumb}
            </div>
        )
    }
    
}

export default withRouter(NPBreadCrumb);