import { useEffect, useState } from 'react'
import '../CustomerProfile.css'
import NPTextInput from '../../../../forms/NPTextInput'
import NPButton from '../../../../comp/NPButton'
import ProfileAPI from '../../../../api/ProfileAPI'

export function FormStep2({ onCompleted, userType }) {

    const [residenceCity, setResidenceCity] = useState()
    const [residenceAddress, setResidenceAddress] = useState()
    const [residenceProvince, setResidenceProvince] = useState()
    const [residenceCountry, setResidenceCountry] = useState()
    const [residenceCAP, setResidenceCAP] = useState()

    const [provinces, setProvinces] = useState()
    const [countries, setCountries] = useState()

    const [error, setError] = useState(null)

    /**
     * Save the data. 
     * Validate first. 
     */
    const save = async () => {

        if (!validate()) return;

        if (!userType || userType == "private") {
            onCompleted({
                residenceAddress, residenceCAP, residenceCity, residenceProvince, residenceCountry
            })
        }
        else if (userType == 'company') {
            onCompleted({
                regOfficeAddress: residenceAddress, regOfficeCAP: residenceCAP, regOfficeCity: residenceCity, regOfficeProvince: residenceProvince, regOfficeCountry: residenceCountry
            })
        }
    }

    /**
     * Loading of countries and provinces
     */
    const loadGeo = () => {

        new ProfileAPI().getCountries().then((data) => {
            let countries = [];
            data.forEach((item) => {
                countries.push({ value: item.alpha2, label: item.name });
            })

            setCountries(countries);
        })

        new ProfileAPI().getITProvinces().then((data) => {
            let provinces = [];
            data.forEach((item) => {
                provinces.push({ value: item.sigla, label: item.nome });
            })

            setProvinces(provinces);
        })
    }

    /**
     * When the province is changed, the residenceProvince needs to be updated with the province VALUE not the LABEL
     * 
     * @param {string} provinceLabel the selected province label
     */
    const onChangeProvince = (provinceLabel) => {

        if (provinceLabel) {

            // In case I can't find the province, at least let's track what the user wrote
            let provinceCode = provinceLabel;

            if (provinces) {
                let filteredList = provinces.filter((item) => { if (item.label.toLowerCase().trim() == provinceLabel.toLowerCase().trim()) return true })
                if (filteredList && filteredList.length > 0) provinceCode = filteredList[0].value
            }

            setResidenceProvince(provinceCode)

        }
    }

    /**
     * When the state is changed, the residenceCountry needs to be updated with the country VALUE not the LABEL
     * 
     * @param {string} countryLabel the selected coutnry label
     */
    const onChangeCountry = (countryLabel) => {

        if (countryLabel) {

            // In case I can't find the country, at least let's track what the user wrote
            let countryCode = countryLabel;

            if (countries) {
                let filteredList = countries.filter((item) => { if (item.label.toLowerCase().trim() == countryLabel.toLowerCase().trim()) return true })
                if (filteredList && filteredList.length > 0) countryCode = filteredList[0].value
            }

            setResidenceCountry(countryCode)

        }

    }

    /**
     * Validate the form
     */
    const validate = () => {

        let err = null;

        if (!residenceAddress || !residenceCAP || !residenceCity || !residenceProvince || !residenceCountry) err = "Devi riempire tutti i campi prima di proseguire";

        setError(err);

        return err == null

    }

    useEffect(loadGeo, [])

    return (
        <div className="form">
            <div className="row mobile-col">
                <div className="col">
                    <NPTextInput label={userType == 'company' ? "Indirizzo della Sede Legale" : "Indirizzo di Residenza"} mandatory={true} onChange={(v) => { setResidenceAddress(v) }} />
                </div>
            </div>
            <div className="row mobile-col">
                <div className="col">
                    <NPTextInput label="CAP" mandatory={true} onChange={(v) => { setResidenceCAP(v) }} />
                </div>
                <div className="col">
                    <NPTextInput label="Città" mandatory={true} onChange={(v) => { setResidenceCity(v) }} />
                </div>
            </div>
            <div className="row mobile-col">
                <div className="col">
                    <NPTextInput suggestions={provinces} label="Provincia" mandatory={true} onChange={onChangeProvince} />
                </div>
                <div className="col">
                    <NPTextInput suggestions={countries} label="Paese" mandatory={true} onChange={onChangeCountry} />
                </div>
            </div>

            {error && <div className="error">{error}</div>}

            <div style={{ marginTop: 24 }}></div>

            <div className="buttons-container">
                <div style={{ flex: 1 }}></div>
                <NPButton label="Prosegui" onClick={save} />
            </div>
        </div>
    )

}