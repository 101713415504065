import './DashboardElements.css'

export function LabeledValue({ label, value }) {

    return (

        <div className="labeled-value">
            <div className="label">{label}</div>
            <div className="value">{value}</div>
        </div>
    )
}

export function Tag({ content }) {
    return (
        <div className={`tag ${content}`}>{content}</div>
    )
}
