import React from 'react';
import './TransactionHeaderCard.css';
import { withTranslation } from 'react-i18next';
import NPCard from '../../../comp/card/NPCard';
import NPLabeledValue from '../../../comp/NPLabeledValue';

/**
 * Displays the key data of the transaction
 */
class TransactionHeaderCard extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { t } = this.props;

        const currency = this.props.transaction.currency ? this.props.transaction.currency : "EUR";

        return (
            <NPCard>
                <div className="tx-header-card">

                    <div className="tx-header-box">
                        <NPLabeledValue textAlign="center" size="large" label="label.license.plates" value={this.props.transaction.licensePlates} />
                    </div>
                    {this.props.transaction.itemType &&
                        <div className="tx-header-box">
                            <NPLabeledValue textAlign="center" size="large" label="label.vehicle.type" value={t('catalog.' + this.props.transaction.itemType)} />
                        </div>
                    }
                    <div className="tx-header-box">
                        <NPLabeledValue textAlign="center" size="large" label="label.amount" type="currency" currency={currency} value={this.props.transaction.price} />
                    </div>

                </div>
            </NPCard>
        )
    }
}

export default withTranslation()(TransactionHeaderCard);