import React, { Component } from 'react';
import './NewTransactionScreen.css';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import withProtection from './hoc/WithProtection';
import withSingleColumnsLayout from './hoc/WithSingleColumnLayout';
import NPCard from '../comp/card/NPCard';
import NPTextInput from '../forms/NPTextInput';
import NPButton from '../comp/NPButton';
import IBAN from 'iban';
import TranAPI from '../api/TranAPI';
import PleggitAnimatedLoader from '../comp/PleggitAnimatedLoader';
import NPCoolRadio from '../forms/NPCoolRadio';
import NPPriceInput from '../forms/NPPriceInput';
import FeesAPI from '../api/FeesAPI';
import moment from 'moment';
import { wait } from '@testing-library/user-event/dist/utils';
import { BlockDivider } from '../comp/BlockDivider';

const cookies = new Cookies();

class NewTransactionScreenV2 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            partyTypeSelected: false
        }

        this.onProceed = this.onProceed.bind(this);
        this.setValidationError = this.setValidationError.bind(this);
        this.clearValidationError = this.clearValidationError.bind(this);
        this.startLoading = this.startLoading.bind(this);
        this.stopLoading = this.stopLoading.bind(this);
        this.postTransaction = this.postTransaction.bind(this);
        this.validate = this.validate.bind(this);
        this.onSelectedPartyType = this.onSelectedPartyType.bind(this);
        this.onPriceChange = this.onPriceChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadPaymentLink = this.loadPaymentLink.bind(this);
        this.waitForFeesToBeLoaded = this.waitForFeesToBeLoaded.bind(this);
        this.setPrice = this.setPrice.bind(this);

    }

    componentDidMount() {
    }

    onSelectedPartyType() {
        if (!this.state.partyType) {
            this.setValidationError({ message: "Seleziona l'opzione corrispondente all'utente che stai registrando" })
            return;
        }
        this.setState({ partyTypeSelected: true })
    }

    setValidationError(error) {
        this.setState({
            error: error
        })
    }

    clearValidationError() {
        this.setState({ error: null })
    }

    async onPriceChange(value) {

        try {

            await this.setPrice(value);

            this.setState({ feesLoading: true });

            const fees = await new FeesAPI().getBuyerSCTAmount(value, moment().format("YYYYMMDD"));

            // Check that the fees actually correspond to the amount inserted (amount - fees = state.price)
            // This is to avoid race conditions, where the first request arrives after the second request => fees are lower than they should
            // This happens at cold start of the fees API
            if (fees.amount - fees.fee == this.state.price) {
                this.setState({ fees: fees.fee, feesLoading: false })
            }
            else this.setState({ feesLoading: false })

        } catch (error) {
            this.setState({ feesLoading: false });
        }
    }

    async setPrice(p) { return new Promise((s, f) => { this.setState({ price: p }, s) }) }
    async startLoading() { return new Promise((s, f) => { this.setState({ loading: true }, s) }) }
    async stopLoading() { return new Promise((s, f) => { this.setState({ loading: false }, s) }) }

    async postTransaction() {

        const agencyUser = cookies.get('user')

        const result = await new TranAPI().postTransaction({
            licensePlates: this.state.licensePlates,
            price: this.state.price,
            fees: this.state.fees,
            agencyFees: this.state.agencyFees,
            buyerEmail: this.state.emailBuyer, 
            sellerEmail: this.state.emailSeller, 
            agencyId: agencyUser.userId, 
            agencyEmail: agencyUser.email
        })

        if (result.code == 400) throw result

        return result;
    }

    partyTypeAsString(type) {
        if (type.toLowerCase() == "seller") return "Venditore"
        return "Compratore"
    }

    async validate() {

        return new Promise((s, f) => {

            if (!this.state.emailBuyer || !this.state.emailSeller) {
                this.setValidationError({ message: "Per favore, inserisci sia la mail del Compratore che del Vendtitore." }, () => { f({ validationErrors: true }) })
                return;
            }

            if (!this.state.licensePlates || !this.state.price) {
                this.setValidationError({ message: "Per favore, inserisci la targa e il prezzo del veicolo." }, () => { f({ validationErrors: true }) })
                return;
            }

            this.setValidationError(null);
            s({ validationErrors: false });

        })
    }

    async loadPaymentLink(tid) {

        const result = await new TranAPI().getPaymentLink(tid);

        if (result.paymentLink) return result.paymentLink;
    }

    async waitForFeesToBeLoaded() {

        if (this.state.feesLoading) {
            await wait(100);
            await this.waitForFeesToBeLoaded();
        }
    }

    onCancel() {
        this.props.history.goBack();
    }

    async onProceed() {

        try {

            await this.validate();

            await this.startLoading();

            await this.waitForFeesToBeLoaded();

            const result = await this.postTransaction();

            let plink;
            if (this.state.partyType == 'buyer') {

                plink = await this.loadPaymentLink(result.insertedId);
            }

            await this.stopLoading();

            this.props.history.push('/dashboard', { txInserted: true, tid: result.insertedId, partyType: this.state.partyType });

        } catch (error) {

            console.log(error);

            if (error.code) this.setValidationError({ message: error.errors[0] })
            else this.setValidationError({ message: "Si è verificato un errore inatteso." })
        }

    }

    async validateIBAN(value) {

        if (!IBAN.isValid(value)) throw { message: "L'IBAN inserito non è valido." }

    }

    render() {

        return (
            <div className="dashboard-screen screen">

                <NPCard>
                    <div className="form-title">
                        {this.state.loading ? "Registrazione in corso.." : "Registra la Transazione"}
                    </div>
                    <div className="content">
                        <BlockDivider title="Dati delle Controparti" />
                        <NPTextInput label="Indirizzo email Compratore" placeholder="Inserisci la mail del Compratore" onChange={(value) => { this.setState({ emailBuyer: value }) }} mandatory={true} />
                        <NPTextInput label="Indirizzo email Venditore" placeholder="Inserisci la mail del Venditore" onChange={(value) => { this.setState({ emailSeller: value }) }} mandatory={true} />

                        <BlockDivider title="Dati del Veicolo" />
                        <NPTextInput style="short" label="Targa del Veicolo" placeholder="Inserire la targa" transform="uppercase" mandatory={true} onChange={(value) => { this.setState({ licensePlates: value }) }} />
                        <NPPriceInput style="short" label={`Prezzo di ${this.state.partyType == 'seller' ? "vendita" : "acquisto"} del Veicolo`} placeholder="Inserire il prezzo" type="number" mandatory={true} onChange={this.onPriceChange} />

                        <BlockDivider title="Commissioni" />
                        <div className="fees-container">
                            <div className="label">Commissioni Pleggit: </div>
                            {this.state.feesLoading && <div className="fees-loading-container"><PleggitAnimatedLoader /></div>}
                            {!this.state.feesLoading && this.state.fees && <div className="fees">€ {this.state.fees}</div>}
                        </div>
                        <NPPriceInput style="short" label={`Commissioni Agenzia`} type="number" mandatory={false} placeholder="Commissioni da applicare" onChange={(value) => { this.setState({ agencyFees: value }) }} />
                        {this.state.partyType == 'seller' &&
                            <NPTextInput label="IBAN" placeholder="Inserire l'IBAN del Venditore" transform="uppercase" mandatory={true} validator={this.validateIBAN} onChange={(value) => { this.setState({ iban: value }) }} />
                        }
                    </div>
                    {this.state.loading &&
                        <div className="loader-container">
                            <PleggitAnimatedLoader />
                        </div>
                    }

                    {this.state.error &&
                        <div className="error-container">
                            {this.state.error.message}
                        </div>
                    }

                    <div className="buttons-container">
                        <NPButton label="Indietro" filled={false} outline={true} onClick={() => { this.props.history.goBack() }} />
                        <div style={{ flex: 1 }}></div>
                        <NPButton label="Prosegui" onClick={this.onProceed} />
                    </div>
                </NPCard>

            </div>
        )
    }
}

export default withProtection(
    withSingleColumnsLayout(
        withRouter(withTranslation()(NewTransactionScreenV2))
    )
);