import React from 'react';
import { withTranslation } from 'react-i18next';
import './NPTextInput.css';
import { ReactComponent as WarningSVG } from '../icons/exclamation-mark.svg';
import { ReactComponent as TickSVG } from '../icons/tick.svg';
import { ReactComponent as InfoSVG } from '../icons/info.svg';
import NPPopup from '../comp/popup/NPPopup';
import helpMeSVG from '../images/help.svg';
import NPButton from '../comp/NPButton';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';

/**
 * Input for text
 * 
 * Parameters: 
 * 
 *  - label                 :   (MAND) the label for the text input
 *  - placeholder           :   (OPT) the placeholder text
 *  - mandatory             :   (OPT, default false) Set to true if this field is mandatory
 *  - prefilled             :   (OPT, default none) Prefill the field with a value
 *  - validator             :   (OPT, default none) A custom validator function to call
 *                              The function must return a Promise where failure() will be called if there are validation errors. 
 *                              A validation error must be an object {message: ""}
 *  - transform             :   (OPT, default none) Transforms the text in the input 
 *                              Possible values: "uppercase" (to transform the text in uppercase)
 *  - type                  :   (OPT, default 'number') Type of input
 *                              Supported values: 'number'
 *  - style                 :   (OPT, default 'normal') Style of the input. This determines, for example, how long the input will be
 *                              Supported values: 'shortest', 'short', 'normal'
 *  - maxLength             :   (OPT, default none) Sets the max length accepted by this field
 *  - minLength             :   (OPT, default none) Sets the min length accepted by this field
 *  - button                :   (OPT, default none) Adds a button to the side of the input to perform an action on the value
 *                              Important: the presence of the button changes the behaviour of the validation. The validation is now only performed when clicking the button. 
 *                              The button field is an { label: <string> }
 *  - disableValidation     :   (OPT, default false) disable input validation
 *  - icon                  :   (OPT, default null) an icon to show in the text field (e.g. the search icon)
 * 
 * Listeners
 * 
 *  - onChange              :   (OPT) listens to the change of the value 
 *  - onPressEnter          :   (OPT) reacts to the user pressing the Enter key
 */
class NPPriceInput extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            validationErrors: null,
            validationPopupOpen: false,
            validationPopupPosition: { top: 0, left: 0 },
            infoPopupOpen: false,
            infoPopupPosition: { top: 0, left: 0 },
            infoPopupWidth: 0,
            value: this.props.prefilled ? this.props.prefilled : ''
        };

        this.infoIconWidth = 20;
        this.infoIconMargin = 12;
        this.feedbackIconMargin = 12;

        this.validate = this.validate.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        // Define the position of the validation popup
        let element = this.el;

        this.setState({
            validationPopupPosition: {
                top: element.clientHeight / 2,
                left: element.clientWidth + 12
            },
            infoPopupPosition: {
                top: element.clientHeight / 2,
                left: element.clientWidth - 34
            },
            infoPopupWidth: element.clientWidth / 1.3
        })

    }

    onKeyDown(key) {

        if (key.keyCode == 13 && this.props.onPressEnter) this.props.onPressEnter();
    }

    onBlur(event) {

        let value = event.target.value;

        if (!this.props.button) this.validate(value);

    }

    validate(value) {

        if (this.props.disableValidation) return;

        const { t } = this.props;

        let validationErrors = [];
        let validationPromises = [];

        // Validations
        validationPromises.push(new Promise((success, failure) => {

            // Mandatoriness
            if (this.props.mandatory) {
                if (!value) validationErrors.push({ message: t('forms.validation.error.mandatory') });
                else if (value.trim() == '') validationErrors.push({ message: t('forms.validation.error.mandatory') });
            }

            // Check min length
            if (value && this.props.minLength && value.length < this.props.minLength) validationErrors.push({ message: t('forms.validation.error.minLength') + ' ' + this.props.minLength })

            success();
        }));

        // If a validator has been passed use it
        if (this.props.validator) validationPromises.push(new Promise((success, failure) => {

            this.props.validator(value).then(() => { success(); }, (validationError) => {

                validationErrors.push(validationError);

                success();

            });
        }));

        Promise.all(validationPromises).then(() => {

            // Show validation errors, if any
            if (validationErrors.length > 0) {
                this.setState({
                    validationErrors: validationErrors
                })
            }
            else this.setState({ validationErrors: [] });
        })

    }

    updateAmount(event) {

        let isNumber = /^[0-9]+((\,|\.)([0-9])*)?$/.test(event.target.value);

        if (isNumber) {
            this.setState({
                amount: event.target.value.replace(',', '.')
            }, () => {

                // Callback, if any
                if (this.props.onAmountChange) this.props.onAmountChange(this.state.amount);
            })
        }
        else this.setState({ amount: '' })
    }

    /**
     * Changes the value
     * @param {any} val the new value
     */
    changeValue(val) {

        let value = val.target.value;
        let formattedValue = value;
        let noDotsValue = value ? value.replace(/\./g, '') : '';
        if (value) {

            // Transform in uppercase, if requested
            if (this.props.transform == 'uppercase') value = value.toUpperCase();

            // Check if it is supposed to be a number
            if (this.props.type == 'number') {

                let isNumber = /^[0-9]+((\,|\.)([0-9])*)?$/.test(value);

                if (isNumber) {
                    // value = value.replace(',', '.');
                    formattedValue = parseInt(noDotsValue).toLocaleString('it-IT');
                }
                else {
                    value = '';
                    formattedValue = '';
                }

                // Add thousands separator
            }

            // Check max length
            if (this.props.maxLength) {
                value = value;
                if (value.length > this.props.maxLength) value = value.substring(0, this.props.maxLength);
            }
        }

        this.setState({
            value: noDotsValue,
            formattedValue: formattedValue,
        }, () => {
            if (this.props.onChange) this.props.onChange(this.state.value);
        })
    }

    /**
     * Reacts to the click of the button (if there)
     */
    onButtonClick() {

        if (!this.props.button) return;

        this.validate(this.state.value);
    }

    render() {

        const { t } = this.props;

        // Classes 
        let widgetClass = "np-text-input";
        if (this.props.style) widgetClass += ' ' + this.props.style;

        let textInputClass = 'outline-primary';
        if (this.state.validationErrors && this.state.validationErrors.length > 0) textInputClass += ' border-complementary';
        else textInputClass += ' border-ok';

        let textInputContainerClass = 'text-input-container';

        // Feedback icon (Validation result)
        let icon;
        let iconRightPosition = (this.props.info ? (this.infoIconMargin + this.infoIconWidth + this.feedbackIconMargin) : this.feedbackIconMargin) + 'px';
        if (this.state.validationErrors && this.state.validationErrors.length > 0) icon = (
            <div className="feedback-icon complementary" style={{ right: iconRightPosition }}>
                <WarningSVG />
            </div>
        )
        else if (this.state.validationErrors && this.state.validationErrors.length == 0) icon = (
            <div className="feedback-icon primary" style={{ right: iconRightPosition }}>
                <TickSVG />
            </div>
        )
        else if (this.props.icon) icon = (
            <div className="feedback-icon primary" style={{ right: iconRightPosition }}>
                {this.props.icon}
            </div>
        )

        // Info icon
        let infoIcon;
        if (this.props.info) infoIcon = (
            <div className="info-icon accent" onMouseOver={() => { this.setState({ infoPopupOpen: true }) }} onMouseOut={() => { this.setState({ infoPopupOpen: false }) }}>
                <InfoSVG />
            </div>
        )

        // Info popup
        let infoPopup;
        if (this.props.info && this.state.infoPopupOpen) infoPopup = (
            <NPPopup
                position={this.state.infoPopupPosition}
                style="info"
                horizontalPlacement="left"
                verticalPlacement="centered"
                width={this.state.infoPopupWidth}
            >
                <div className="info-popup-content">
                    <div className="info-popup-img"><img src={helpMeSVG} width="100%" /></div>
                    <div className="info-popup-text">{this.props.info}</div>
                </div>
            </NPPopup>
        )

        // Validation Popup
        let popup;
        if (this.state.validationErrors && this.state.validationErrors.length > 0) popup = (
            <NPPopup
                position={this.state.validationPopupPosition}
                verticalPlacement="centered"
                style="validation"
                wrapText={false}
            >
                {this.state.validationErrors[0].message}
            </NPPopup>
        )

        // Button
        let button;
        if (this.props.button) button = (
            <div className="npti-button-container">
                <NPButton label={this.props.button.label} onClick={this.onButtonClick} />
            </div>
        )

        return (
            <div className={widgetClass}>
                <div className="np-input-label">
                    {this.props.label}
                </div>
                <div className="npti-content">
                    <div className={textInputContainerClass} ref={(el) => { this.el = el }} onMouseOver={() => { this.setState({ validationPopupOpen: true }) }} onMouseOut={() => { this.setState({ validationPopupOpen: false }) }}>
                        <div className="currency">€</div>
                        <input
                            className={textInputClass}
                            type={this.props.type == 'password' ? "password" : "text"}
                            placeholder={this.props.placeholder}
                            onBlur={this.onBlur}
                            value={this.state.formattedValue}
                            onChange={this.changeValue}
                            onKeyDown={this.onKeyDown}
                        />
                        {infoIcon}
                        {infoPopup}
                        {icon}
                        {this.state.validationErrors && this.state.validationPopupOpen && popup}
                    </div>
                    {button}
                </div>
            </div >
        )
    }

}

export default withTranslation()(NPPriceInput);