import React, { Component, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import './TransactionDetailScreen.css';
import withProtection from './hoc/WithProtection';
import { withRouter } from 'react-router-dom';
import ProfileAPI from '../api/ProfileAPI';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import withSingleColumnsLayout from './hoc/WithSingleColumnLayout';
import TransactionHeaderCard from './parts/tx/TransactionHeaderCard';
import TranAPI from '../api/TranAPI';
import { AgencyTransactionPaymentCard } from '../comp/card/tx/AgencyTransactionPaymentCard';
import ProfileForTxCard from '../comp/card/profile/ProfileForTxCard';
import { AgencyPropertyTransferCard } from '../comp/card/tx/AgencyPropertyTransferCard';
import NPCard from '../comp/card/NPCard';
import NPLabeledValue from '../comp/NPLabeledValue';
import { AgencySellerPaymentCard } from '../comp/card/tx/AgencySellerPaymentCard';
import { IbanForm } from './parts/dashboard/form/IbanForm';
import { NPCardMessage } from '../comp/card/NPCardMessage';
import Cookies from 'universal-cookie';
import { SellerPaymentDoneCard } from '../comp/card/tx/SellerPaymentDoneCard';

const cookies = new Cookies()

function Content() {

    const [transaction, setTransaction] = useState()
    const [buyerProfile, setBuyerProfile] = useState()
    const [sellerProfile, setSellerProfile] = useState()
    const [buyerProfileLoading, setBuyerProfileLoading] = useState(true)
    const [sellerProfileLoading, setSellerProfileLoading] = useState(true)

    const { tid } = useParams()

    const loggedUser = cookies.get('user');

    const init = () => {
        loadTransactionAndProfiles();
    }

    /**
     * Load the transaction data
     */
    const loadTransactionAndProfiles = async () => {

        const apiResponse = await new TranAPI().getTransactionDetail(tid);

        setTransaction(apiResponse);

        // Load the buyer profile
        if (apiResponse.buyerUserId) {

            let bt = setTimeout(() => { setBuyerProfileLoading(true) }, 400)

            const profile = await loadProfile(apiResponse.buyerUserId);

            clearTimeout(bt)
            setBuyerProfileLoading(false)

            if (profile && profile.code != 403) setBuyerProfile(profile);
            else setBuyerProfile({ email: apiResponse.buyerEmail })
        }
        else setBuyerProfileLoading(false)

        // Load the seller profile
        if (apiResponse.sellerUserId) {

            let st = setTimeout(() => { setSellerProfileLoading(true) }, 400)

            const profile = await loadProfile(apiResponse.sellerUserId);

            clearTimeout(st)
            setSellerProfileLoading(false)

            if (profile && profile.code != 403) setSellerProfile(profile);
            else setSellerProfile({ email: apiResponse.sellerEmail })

        }
        else setSellerProfileLoading(false)
    }

    /**
     * Load a profile
     */
    const loadProfile = async (userId) => {

        const apiResponse = await new ProfileAPI().getProfileByUserId(userId)

        return apiResponse;

    }

    /**
     * Checks if the logged user is the Agency
     * 
     * @returns true is the logged user if the agency
     */
    const loggedUserIsAgency = () => {
        return loggedUser.email != transaction.sellerEmail && loggedUser.email != transaction.buyerEmail;
    }

    /**
     * Checks if the logged user is the Seller
     * 
     * @returns true is the logged user if the seller
     */
    const loggedUserIsSeller = () => {
        return loggedUser.email == transaction.sellerEmail;
    }

    useEffect(init, [])

    if (!transaction) return <div></div>

    return (
        <div className="admin-transaction-detail-screen">

            <TransactionHeaderCard transaction={transaction} />

            <TxDetailsCard transaction={transaction} />

            <AgencyTransactionPaymentCard transaction={transaction} />

            {!transaction.iban &&  loggedUserIsSeller() && <NPCard><IbanForm tx={transaction} onIbanSaved={init} /></NPCard>}

            {transaction.buyerPaid === true && <AgencyPropertyTransferCard tx={transaction} onPropertyTransferred={loadTransactionAndProfiles} />}

            {!transaction.propertyTransferred && !transaction.sellerWireDone && transaction.iban && loggedUserIsSeller() && <NPCard><NPCardMessage text={`Una volta la transazione conclusa, riceverai i fondi a questo IBAN: ${transaction.iban}`}  /></NPCard>}
            
            {transaction.propertyTransferred && loggedUserIsAgency() && <AgencySellerPaymentCard transaction={transaction} />}
            {transaction.propertyTransferred && loggedUserIsSeller() && transaction.iban && <SellerPaymentDoneCard transaction={transaction} />}

            <div className="two-cols">
                <div className="col left">
                    <div className="card-container"><ProfileForTxCard loading={buyerProfileLoading} email={transaction.buyerEmail} profile={buyerProfile} you={loggedUser.email == transaction.buyerEmail} partyType="buyer" highlight={true} highlightColor="primary" /></div>
                </div>
                <div className="col right">
                    <div className="card-container"><ProfileForTxCard loading={sellerProfileLoading} email={transaction.sellerEmail} profile={sellerProfile} you={loggedUser.email == transaction.sellerEmail} partyType="seller" highlight={true} /></div>
                </div>
            </div>

        </div >
    )
}

function TxDetailsCard({ transaction }) {

    return (
        <NPCard>
            <div className='fees-card-content'>
                <NPLabeledValue label="Creata il" value={transaction.createdOn} type="date" />
                <NPLabeledValue label="Commissioni Pleggit" value={transaction.fees} type="currency" />
                <NPLabeledValue label="Commissioni Agenzia" value={transaction.agencyFees ? parseFloat(transaction.agencyFees) : 0} type="currency" />
            </div>
        </NPCard>
    )
}

export default withProtection(
    withSingleColumnsLayout(
        withRouter(withTranslation()(Content)),
        "wide"
    )
);
