import NPAPI from './NPAPI';

export default class AuthAPI {

  activateAccount(activationCode) {
    return new NPAPI().fetch('auth', '/activate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        activationCode: activationCode
      }),
      noAuth: true
    }).then((response) => response.json());

  }

  signIn(username, password, userType) {

    return new NPAPI().fetch('auth', '/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        password: password,
        userType: userType
      }),
      noAuth: true
    }).then((response) => response.json());

  }

  registerUser(data) {

    return new NPAPI().fetch('auth', '/registrations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      noAuth: true
    }).then((response) => response.json());

  }

}
