import { useState } from 'react'
import './NPSquareButton.css'

export function NPSquareButton({ label, icon, onClick }) {

    const [pressed, setPressed] = useState(false)

    const onMouseDown = () => { setPressed(true) }
    const onMouseUp = () => { setTimeout(() => { setPressed(false) }, 100) }

    return (
        <div className={`np-square-button ${pressed ? 'pressed' : ''}`} onClick={() => {onClick()}} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
            {icon && <div className='icon'>{icon}</div>}
            {label}
        </div>
    )
}