import IBAN from 'iban'
import NPTextInput from '../../../../forms/NPTextInput'
import './IbanForm.css'
import { NPCheckBox } from '../../../../comp/NPCheckBox'
import { useState } from 'react'
import NPButton from '../../../../comp/NPButton'
import { NPValidationError } from '../../../../forms/NPValidationError'
import { NPLoadingBar } from '../../../../comp/NPLoadingBar'
import { NPCardMessage } from '../../../../comp/card/NPCardMessage'
import TranAPI from '../../../../api/TranAPI'

export function IbanForm({ tx, onIbanSaved }) {

    const [ibanConfirmed, setIbanConfirmed] = useState(false)
    const [iban, setIban] = useState()
    const [error, setError] = useState()
    const [saving, setSaving] = useState(false)
    const [saved, setSaved] = useState(false)

    /**
     * Verifies if the IBAN is valid
     * 
     * @param {string} value the iban
     */
    const validateIBAN = async (value) => {

        if (!IBAN.isValid(value)) throw { message: "L'IBAN inserito non è valido." }

    }

    /**
     * Reacts to the user clicking the "confirm iban" checkbox
     */
    const onConfirmIbanClick = () => {

        setIbanConfirmed(!ibanConfirmed)
    }

    /**
     * Confirms the form. 
     * Saves the IBAN. 
     */
    const onConfirmForm = async () => {

        // Validate
        if (!iban || !IBAN.isValid(iban)) { setError("Inserisci un IBAN valido."); return; }
        if (!ibanConfirmed) { setError("Devi confermare l'IBAN prima di proseguire."); return; }

        setError(null);

        // Save the IBAN
        const timer = setTimeout(() => { setSaving(true) }, 400)

        const apiResponse = await new TranAPI().postIBAN(tx.id, iban)

        if (apiResponse && (apiResponse.code != 201)) setError(apiResponse.message)

        // Give the User feedback
        setSaved(apiResponse && apiResponse.updated)

        clearTimeout(timer)
        setSaving(false)

    }

    return (
        <div className="iban-form">

            {!saving && !saved &&
                <div className="message">
                    Non hai ancora inserito l'<b>IBAN</b> al quale ricevere i fondi del veicolo <b>{tx.licensePlates}</b>. <br></br>
                    Inserisci l'IBAN qui sotto.
                </div>
            }

            {saving && <NPLoadingBar label="Salvataggio dell'IBAN in corso.." />}

            {!saving && saved && <NPCardMessage text={`L'IBAN è stato salvato.`} onOk={onIbanSaved} />}

            {error && <div style={{ marginTop: 24 }}><NPValidationError errorMessage={error} /></div>}

            {!saving && !saved &&
                <>
                    <div className='input-container'>
                        <NPTextInput label="Inserisci il tuo IBAN" validator={validateIBAN} onChange={(v) => { setIban(v) }} />
                    </div>
                    <div className='input-container'>
                        <NPCheckBox
                            label="Confermo che l'IBAN inserito è corretto e sono consapevole che non avrò modo di cambiarlo durante il resto della transazione."
                            ticked={IBAN.isValid(iban) && ibanConfirmed}
                            onClick={onConfirmIbanClick}
                        />
                    </div>
                    <div className='input-container row'>
                        <div style={{ flex: 1 }}></div>
                        <NPButton label="Confermo l'IBAN" onClick={onConfirmForm} />
                    </div>
                    <div className='input-container'>
                        <p>
                        Una volta il passaggio di proprietà effettuato riceverai i fondi custoditi da Pleggit, da cui verranno trattenute le nostre commissioni.<br />
                        </p>
                        <p>
                        L'ammontare delle commissioni Pleggit per questa transazione è di <b>{tx.fees ? tx.fees.toLocaleString('it-IT', { style: 'currency', currency: "EUR", minimumFractionDigits: 2 }) : 0}</b>.
                        </p>
                    </div>
                </>
            }
        </div>
    )
}