import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import NPButton from './NPButton';
import './NPToolbar.css';
import Cookies from 'universal-cookie';
import pushListener from '../evt/PushListener';
import {ReactComponent as PleggitSVG} from '../images/pleggit-logo.svg';

const cookies = new Cookies();

class NPToolbar extends Component {

    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this);
        this.logoutGoogle = this.logoutGoogle.bind(this);
        this.logoutFB = this.logoutFB.bind(this);
        this.home = this.home.bind(this);
        this.clearUserCookie = this.clearUserCookie.bind(this);
    }

    home() {
        this.props.history.push('/');
    }

    /**
     * Logs out of the app and goes back to the home site front page
     */
    logout() {

        let authProvider = cookies.get('user').authProvider;
        let userId = cookies.get('user').userId;

        if (authProvider == 'google') this.logoutGoogle();
        else this.logoutFB();

        // Logout from pusher
        pushListener.onLogout(userId);

    }

    /**
     * Logs out of the app and goes back to the home site front page
     */
    logoutGoogle() {

        if (!window.gapi) { console.log("ERROR! Google API hasn't been loaded for some reason!"); return; }

        window.gapi.auth2.getAuthInstance().signOut().then((a) => {

            console.log("[logout] - Successfully signed out");

            this.clearUserCookie();

            this.props.history.push('/');

        }, (err) => {
            console.log("[logout] - Couldn't sign out with Google SignOut");
            console.log(err);
        });
    }

    /**
     * Logs out of the app and goes back to the home site front page
     */
    logoutFB() {

        if (!window.FB) { console.log("ERROR! FB API hasn't been loaded for some reason!"); return; }

        window.FB.logout((response) => {

            console.log("[logout] - Successfully signed out");

            this.clearUserCookie();

            this.props.history.push('/');
        })
    }

    /**
     * Clears the user cookie (removes it)
     */
    clearUserCookie() {

        console.log("[logout] - Clearing the user cookie..");

        cookies.remove('user');
    }

    render() {

        const { t } = this.props;

        let menuHighlight = (
            <div className="menu-highlight accent">
            </div>
        )

        let availableMenus = [];
        if (cookies.get('user') && cookies.get('user').role === 'admin') availableMenus.push(
            <div key="menu-admin" className="item-container">
                <NPButton label={t('button.label.admin')} filled={false} onClick={() => { this.props.history.push('/admin') }} />
                {this.props.location.pathname.indexOf('/admin') >= 0 && menuHighlight}
            </div>
        )
        else {
            availableMenus.push((
                <div key="menu-dash" className="item-container">
                    <NPButton label={t('button.label.overview')} filled={false} onClick={() => { this.props.history.push('/dashboard') }} />
                    {this.props.location.pathname == '/dashboard' && menuHighlight}
                </div>
            ));
            // availableMenus.push((
            //     <div key="menu-tx" className="item-container">
            //         <NPButton label={t('button.label.transactions')} filled={false} />
            //         {this.props.location.pathname == '/transactions' && menuHighlight}
            //     </div>
            // ));
            availableMenus.push((
                <div key="menu-profile" className="item-container">
                    <NPButton label={t('button.label.profile')} filled={false} onClick={() => { this.props.history.push('/profile') }} />
                    {this.props.location.pathname == '/profile' && menuHighlight}
                </div>
            ));
        }

        return (
            <div className="np-toolbar fill-primary dark-background">
                <div className="logo font-righteous font-xxlarge" onClick={this.home}>
                    <PleggitSVG />
                </div>
                <div className="menu">
                    {availableMenus}
                </div>
                <div className="logout-container">
                    <NPButton label={t('button.label.logout')} outline={true} filled={false} onClick={this.logout} />
                </div>
            </div>
        )
    }
}

export default withRouter(withTranslation()(NPToolbar));