import './NPCheckBox.css'

import { ReactComponent as TickSVG } from '../icons/tick.svg';

/**
 * Parameters
 *  - label             :   (MAND) label to show
 *  - ticked            :   (OPT) pass true to show as ticked
 *  - editMode          :   (OPT) pass true to make the checkbox editable
 *  - links             :   (OPT, a list of links to display)
 *                          A Link is an object {label: string, link: url string}
 * 
 * Callbacks:
 *  - onClick           :   (OPT) reacts to the click
 */
export function NPCheckBox({ label, ticked, editMode, onClick, links }) {

    return (
        <div className={`np-checkbox edit-${editMode}`} >
            <div className='row' onClick={onClick}>
                <div className="box">
                    {ticked === true && <TickSVG />}
                </div>
                <div className="label">
                    {label}
                </div>
            </div>
            <div className='links'>
                {links && links.map((link) => <Link link={link} key={Math.random()} />)}
            </div>
        </div>
    )
}

function Link({ link }) {

    return (
        <div className='link'>
            <a href={link.link} target='_blank'>
                {link.label}
            </a>
        </div>
    )

}